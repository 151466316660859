<template>
    <div class="operation-platform-table">
        <!-- height="700" -->
        <!-- max-height="600" -->
        <el-table
            border
            height="400"
            style="width:100%;"
            row-key="rowKey"
            ref="operationPlatformTable"
            tooltip-effect="light"
            :data="tableData"
            v-loading="tableDataLoading"
            :span-method="objectSpanMethod"
            @selection-change="handleSelectionChange"
            @expand-change="handleExpandChange">
            <!-- :row-class-name="rowClassName" -->
            <el-table-column
                fixed
                width="42"
                align="center"
                header-align="center"
                type="selection"
            ></el-table-column>
            <el-table-column
                fixed
                align="left"
                width="116"
                prop="candidateName"
                label="候选人姓名"
                >
                <template slot-scope="{row}">
                    <!-- <a 
                        class="candidateName-cell ellipsis"
                        :title="row.candidateName"
                        target="_blank"
                        :href="`/Headhunting/MyCompany.html#/candidateDetail/${row.candidateId}`"
                        >{{row.candidateName}}</a> -->
                    <a 
                        class="candidateName-cell ellipsis"
                        :title="row.candidateName"
                        target="_blank"
                        :href="`/#/candidateDetail/${row.candidateId}`"
                        >{{row.candidateName}}</a>
                    <candidate-operation
                        :row="row"
                        @operation-callback="handleOperationCallback"
                        />
                </template>
            </el-table-column>
            <!-- class-name="time-sort-cell" -->
            <el-table-column
                fixed
                align="left"
                width="140"
                prop="company"
                label="公司"
                :show-overflow-tooltip="true"
                >
            </el-table-column>
            <el-table-column
                fixed
                align="left"
                width="140"
                prop="title"
                label="职位"
                :show-overflow-tooltip="true"
                >
            </el-table-column>
            <el-table-column
                v-for="(item, index) in titleStore.slice(3)"
                :key="index"
                header-align="left"
                :label="tableTitleMap[item].label"
                :min-width="tableTitleMap[item].minWidth"
                :class-name="tableTitleMap[item].customClass"
                :show-overflow-tooltip="tableTitleMap[item].showOverflowTooltip"
            >
                <template slot-scope="{row}">

                    <template v-if="item == 'recommendedAt'">
                        {{row[item] | formatDate }}
                    </template>

                    <template v-else-if="item == 'recommendationStatus'">
                        {{operationStatus[row[item]]}}
                    </template>

                    <template v-else-if="item == 'receiverName'">
                        <template v-if="row[item]">
                            {{row[item]}}
                        </template>
                        <span 
                            v-else
                            class="cursor-pointer"
                            @click="claimOperation(row)" >
                            <font-icon class="table-body-icon-big icon-inquiry" href="#icon-renling"></font-icon>
                            <span class="color-warn">&nbsp;认领</span>
                        </span>
                    </template>

                    <template v-else-if="item == 'businessTags'">
                        <!-- 标签 -->
                        <tag-popover 
                            v-if="row.businessTags.length > 0"
                            :tagData="row.businessTags"
                            :tagDataIds="[row.candidateId]"
                            @show-tag-management="showTagManage"
                            :row="row"
                            />
                       
                        <!-- 认领了才能打标签 -->
                        <span
                            v-if="row.businessTags.length == 0 && row.receiverName && row.receiverName == userInfo.realName"
                            class="cursor-pointer" 
                            @click="tagOperation(row)">
                            <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_lable"></font-icon>
                            <span class="color-warn">&nbsp;添加</span>
                        </span>
                    </template>

                    <template v-else-if="item == 'followRecordsCount'">
                        <!-- 跟进 -->
                        <!-- <inquiry-log
                            :resume="scope.row"
                            @update-item="handleUpdateItem"
                            v-if="scope.row.resumeListItem.inquiryLogsCount>0"
                        ></inquiry-log> -->
                        <!-- v-else @click="resumeOperation('addVisit', {resume: scope.row})" -->
                        <trace-log 
                            :ref="'trace_' + row.id"
                            v-if="row.followRecordsCount > 0"
                            :row="row"
                            @trace-opertaion-cb="handleTraceOpertaionCb"
                            />
                        <span
                            v-if="row.followRecordsCount == 0 && row.receiverName && row.receiverName == userInfo.realName"
                            class="cursor-pointer" 
                            @click="traceOperation(row)">
                            <font-icon class="table-body-icon-big icon-inquiry" href="#icon-track-red"></font-icon>
                            <span class="color-warn">&nbsp;添加</span>
                        </span>
                    </template>

                    <template v-else>
                        {{row[item]}}
                    </template>
                </template>
            </el-table-column>

            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>

        <!-- 认领弹窗 -->
        <!-- title="请选择转移给到人员"  -->
        <el-dialog 
            width="360px"
            title="请选择转移给到的人员"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :visible.sync="claimDialogVisible">
            <el-form 
                ref="claimForm"
                :model="claimForm"
                :rules="claimRules">
                <el-form-item >
                    <el-select 
                        v-model="claimForm.person" 
                        placeholder="请选择" 
                        style="width: 100%">

                        <el-option 
                            v-for="(item, index) in personList"
                            :key="'persion_' + index"
                            :label="item.realName + '@' + item.nickName" 
                            :value="item.userId"
                            ></el-option>
                            
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="claimDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleTransformConfirm">确定</el-button>
            </div>
        </el-dialog>

        <!-- 添加和编辑跟进记录 -->
        <trace-dialog 
            ref="traceDialog"
            />


        <!-- 添加标签 -->
        <tag-dialog 
            ref="tagDialog"
            @show-tag-management="showTagManage"
            />

        <!-- 标签管理 -->
        <tag-manage
            ref="tagManage"
            />

    </div>
</template>

<script>
import moment from 'moment';
import { tableTitleMap } from '#/js/config/operationPlatformTitle.js';
import { operationStatus } from '../config-data/status-map.js';
import operationPlatformService from '#/js/service/operationPlatformService.js';

import CandidateOperation from '../component/candidate-operation.vue';
import TraceLog from '../component/trace-log.vue';
import TraceDialog from '../component/trace-dialog.vue';
import TagPopover from '../component/tag-popover.vue';
import TagDialog from '../component/tag-dialog.vue';
import TagManage from '../component/tag-manage.vue';

export default {
    name: 'operation-platform-table',
	components: {
        CandidateOperation,
        TraceLog,
        TraceDialog,
        TagPopover,
        TagDialog,
        TagManage,
	},
    props: {
        titleStore: {
            type: Array,
            default: () => [],
        },
        tableData: {
            type: Array,
            default: () => [],
        },
        tableDataLoading: {
            type: Boolean,
            default: false,
        }
    },
	data() {
		return {
            tableType: 'operationPlatform',
            tableTitleMap: tableTitleMap,
            operationStatus: operationStatus,
            // tableData: [
            //     {
            //         candidateName: '杨洪磊',
            //         company: '腾讯',
            //         job: '产品经理',
            //         recommendTime: '2021-01-01 12:30',
            //         recommendCompany: '阿里巴巴',
            //         recommendJob: '产品经理',
            //         race: '产品',
            //         status: '面试淘汰',
            //         recommender: '杨洪磊',
            //         creater: '杨洪磊',
            //         owner: '杨洪磊',
            //         tag: ['adfadf', 'adfadfads'],
            //         process: 2,
            //     },
            //     {
            //         candidateName: '杨洪磊1',
            //         company: '腾讯2',
            //         job: '产品经理2',
            //         recommendTime: '2021-02-02 12:30',
            //         recommendCompany: '阿里巴巴2',
            //         recommendJob: '产品经理2',
            //         race: '产品2',
            //         status: '面试淘汰2',
            //         recommender: '杨洪磊2',
            //         creater: '杨洪磊2',
            //         owner: '杨洪磊2',
            //         tag: ['adfadf', 'adfadfads'],
            //         process: 5,
            //     },
            //     ],
            // tableDataLoading: false,

            filterStore: [],


            claimDialogVisible: false,
            personList: [],
            claimForm: {
                person: '',
            },
            claimRules: {
                person: [
                    { required: true, message: '请选择转移到的人员', trigger: 'blur' }
                ],
            },

            currentRow: null,
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    created() {
        this.getPersonList();
    },
	mounted() {
		
	},
    filters: {
        formatDate(string) {
            return moment(string).format('YYYY-MM-DD HH:mm');
        },
    },
    
	methods: {
        getPersonList() {
            operationPlatformService.getFirmPlatformOperationers()
            .then(res => {
                this.personList = res || [];
            });
        },
        // 处理表单合并
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if([0,1,2,3].includes(columnIndex) || 
            ['认领人', '标签', '跟进详情'].includes(column.label)) {
                if(row.hasOwnProperty('rowspan')) {
                    if(row.rowspan == 0) {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    } else {
                        return {
                            rowspan: row.rowspan,
                            colspan: 1
                        };
                    }
                }
            }
        },
      
        handleSelectionChange(val) {
            this.$emit('selection-change', val);
        },
        handleExpandChange() {},
        handleOperationCallback(type, row) {
            switch(type){
                case 0:
                    this.$confirm(`确认是否转移候选人“${row.candidateName}”？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        closeOnClickModal: false,
                    }).then(() => {
                        this.claimDialogVisible = true;
                        this.currentRow = row;
                    }).catch(() => {});
                    break;
                case 1:
                    this.tagOperation(row);
                    break;
                case 2:
                    this.traceOperation(row);
                    break;
            }
        },
        
        handleTransformConfirm() {
            this.$refs.claimForm.validate((valid) => {
                if (valid) {
                    const params = {
                        snapshotIds: [this.currentRow.id],
                        toReceiverId: this.claimForm.person,
                    };
                    const _transformPersonName = this.personList.find(item => item.userId == this.claimForm.person).realName;
                    
                    operationPlatformService.candidateSnapshotTransfer(params)
                    .then(res => {
                        this.$emit('operation-cb', 'receiverName', [this.currentRow.id], _transformPersonName);
                        shortTips(`候选人“${this.currentRow.candidateName}”转移成功!`);
                    })
                    .finally(() => {
                        this.claimDialogVisible = false;
                    });
                }
            });
        },
        // 认领
        claimOperation(row) {
            this.$confirm(`确认是否认领候选人“${row.candidateName}”？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    const params = {
                        SnapshotIds: [row.id],
                    };
                    operationPlatformService.batchAcceptCandidate(params)
                    .then(res => {
                        // 将数据merge进去
                        this.$emit('operation-cb', 'receiverName', [row.id]);
                        shortTips(`成功认领候选人“${row.candidateName}”`);
                    });
                }).catch(() => {});
        },

        traceOperation(row, traceItem) {
            this.$refs.traceDialog.show(row, traceItem);
        },
        
        handleTraceOpertaionCb(row, traceItem) {
            this.traceOperation(row, traceItem);
        },
        
        tagOperation(row) {

            const list = row.businessTags.map(item => ({
                candidateId: item.candidateId,
                tagId: item.id,
                tagName: item.name,
            }));
            this.$refs.tagDialog.show(false, [row.candidateId], list);
        },
        batchTagOperation(ids) {
            this.$refs.tagDialog.show(true, ids);
        },
        showTagManage() {
            this.$refs.tagManage.show();
        },
        
    }
}
</script>
<style lang="scss" scope>

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.operation-platform-table {
    flex-grow: 1;
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-flow: column nowrap;

    letter-spacing: 0px;
    overflow: hidden;
    .el-table {
        // height: 60.6% !important;
        // height: 100%;
        min-height: 244px;
        overflow-y: auto;
        color: #666;

        .el-table__row {
            td:nth-last-of-type(1) {
                border-right-color: #EBEEF5;
            }
        }
        // .el-table__fixed-body-wrapper{
        //     table{
        //         border-bottom: 1px solid #EBEEF5;
        //     }
        // }
        

        .el-loading-mask {
            z-index: 900;
        }
        th {
            padding: 0 0 0 20px;
            height: 42px;
            line-height: 42px;
            background-color: #E8E8E8;
            border-color: #CCC;
            color: #666;
            .el-checkbox {
                padding: 0 10px;
            }
            .cell {
                padding: 0;
                .caret-wrapper {
                    display: none;
                }
                .el-dropdown {
                    float: left;
                    width: 100%;
                    padding: 0;
                    text-align: center;
                    &.time-sort-dropdown {
                        line-height: unset;
                        margin-top: -7px;
                    }
                }
                .el-checkbox {
                    display: none;
                }
            }
            &.name-cell .table-slot {
                text-indent: 0;
            }
        }
        td {
            padding: 0;
            height: 40px;
            line-height: 40px;
            .cell {
                white-space: nowrap;
                .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #fff;
                    border-color: $primary;

                    &::after {
                        border-color: $primary;
                    }
                }
            }
            .table-avatar {
                display: block;
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }
            &.btn-menu .cell {
                padding: 0;
            }
            &.avatar-cell .table-slot {
                text-indent: 0;
            }
            .recommend-avatar {
                position: relative;
            }
        }
        .el-table__row {
            .el-checkbox__inner {
                border-color: #999;
            }
            &.lazy {
                td {
                    color: #BBBBBB;
                    &.name-cell .cell .candidate-link {
                        color: #bbb;
                        &:hover {
                            color: $primary;
                        }
                    }
                    &.expand-cell {
                        .el-table__expand-icon {
                            color: #bbb;
                        }
                        .el-table__expand-icon--expanded {
                            color: $primary;
                        }
                    }
                }
                .el-checkbox__inner {
                    border-color: #BBBBBB;
                }
            }
        }

        .table-slot {
            padding: 0;
            text-indent: 10px;
            line-height: unset;
        }
        .table-slot-content {
            text-indent: 0;
        }
        .is-center .table-slot {
            text-indent: 0;
        }
        .time-sort-cell .cell {
            line-height: 40px;
        };
        .expand-cell {
            border-right: none;
            .el-table__expand-icon--expanded {
                color: $primary;
            }
            .el-table__expand-icon > .el-icon {
                z-index: 10;
                &.el-icon-arrow-right {
                    font-weight: bold;
                }
            }
        }
        .name-cell .cell {
            padding-left: 5px;
            .candidate-link {
                color: #666;
                &:hover {
                    color: $primary;
                }
            }
        }
        .bg-green-dark {
            background-color: #ebf8f5;
        }
        .bg-orange-dark {
            background-color: #ffe4ce;
        }
        .el-table__empty-block {
            .el-table__empty-text {
                line-height: inherit;
            }
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
        .el-table-column--selection {
            border-right: none;
        }
        .recommend-dynamic {
            position: relative;
            z-index: 5;
        }
        .candidateName-cell{
            cursor: pointer;
            display: inline-block;
            max-width: 70px;
            vertical-align: bottom;
            &:hover {
                color: #38BC9D;
            }
        }
    }
    .el-table__fixed {
        .recommend-dynamic {
            visibility: hidden;
        }
    }
    
}
</style>