<template>
    <el-dialog
        :show-close="false"
        v-loading="loading"
        :visible.sync="isShow"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :custom-class="labelManagementDialogGuide ? 'tag-management-dialog is-guide' : 'tag-management-dialog'">
        <span class="tag-management-title" slot="title">
            编辑标签
            <span style="font-size: 12px; margin-left: 5px;">双击可编辑标签名称</span>
            <i
                class="el-icon-close"
                @click="hideDialog"
            ></i>
            <span
                class="add-category-tag"
                :class="{'label-management-dialog-guide': labelManagementDialogGuide && guideStep === 1}">
                <span
                    class="text-operate-btn"
                    :class="{'is-dialog-guide': labelManagementDialogGuide && guideStep === 1}"
                    @click="showInnerDialog('addCategory')">
                    添加标签类别
                </span>
                <span
                    class="text-operate-btn"
                    :class="labelManagementDialogGuide && guideStep === 1 ? 'text-operate-btn-hide' : ''"
                    @click="showInnerDialog('addTag')">
                    添加标签
                </span>
                <div class="step-guide" v-if="labelManagementDialogGuide && guideStep === 1">
                    <span class="step-guide-box">
                        <span class="guide-content">
                            标签新增分类功能，点击添加类别，进行标签分类。
                        </span>
                        <span class="guide-btn">
                            <span class="text-operate-btn" @click="guideStep = 2">下一步</span>
                        </span>
                    </span>
                    <span class="step-guide-arrows"></span>
                </div>
            </span>
        </span>
        <div class="category-tag-list" v-if="categoryTags.length > 0">
            <template v-for="(category, categoryIndex) in categoryTags">
                <div
                    class="category-tag-item"
                    :key="categoryIndex"
                    v-if="!(category.categoryId === '0' && (category.tagItems && category.tagItems.length === 0))">
                    <div class="category-header">
                        <span class="category-title">
                            {{category.categoryName}}
                        </span>
                        <i
                            class="category-edit el-icon-edit"
                            v-if="category.categoryId !== '0'"
                            @click="showInnerDialog('editCategory', category)"
                        ></i>
                        <i
                            class="category-delete el-icon-delete"
                            v-if="category.tagItems.length === 0 && category.categoryId !== '0'"
                            @click="showInnerDialog('deleteCategory', category)"
                        ></i>
                    </div>
                    <div class="tag-list">
                        <template v-if="category.tagItems.length > 0">
                            <span
                                v-for="(tag, tagIndex) in category.tagItems"
                                :key="tagIndex"
                                class="tag-item-container">
                                <span
                                    class="tag-item"
                                    :class="{'is-select': tag.selected, 'is-edit': tag.isEdit}"
                                    @click="selectTag(categoryIndex, tagIndex, tag)"
                                    @dblclick="editTag(categoryIndex, tagIndex, tag)"
                                    title="双击可编辑标签名称">
                                    {{tag.tagName}}
                                    <i class="el-icon-circle-check selected-icon"></i>
                                </span>
                                <el-input
                                    class="tag-item-input"
                                    :ref="'tagEditInput_' + categoryIndex + '_' + tagIndex"
                                    v-model="tag.tagName"
                                    :maxlength="20"
                                    @input="(val) => inputTag(categoryIndex, tagIndex)"
                                    @keyup.enter.native="(ev) => confirmEditTag(categoryIndex, tagIndex, tag)"
                                    v-if="category.tagItems[tagIndex].isEdit">
                                    <span
                                        slot="suffix"
                                        class="el-icon-check"
                                        @click="confirmEditTag(categoryIndex, tagIndex, tag)"
                                    ></span>
                                </el-input>
                            </span>
                        </template>
                        <span class="tag-list-null" v-else>
                            暂无标签
                        </span>
                    </div>
                </div>
            </template>
        </div>
        <div class="no-tag-category" v-if="categoryTags.length === 0">
            <span class="empty-img"></span>
            <p class="empty-data">暂无数据</p>
        </div>
        <el-dialog
            :title="innerDialogTitle"
            :show-close="false"
            custom-class="tag-management-inner-dialog"
            :visible.sync="innerDialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            append-to-body
            v-loading="innerDialogLoading">
            <el-form
                ref="innerDialogForm"
                :model="innerDialogForm"
                label-width="80px"
                size="mini"
                v-if="innerType.indexOf('delete') === -1">
                <el-form-item
                    prop="categoryName"
                    label="类别名称"
                    :rules="innerDialogFormRules.categoryName"
                    v-if="innerType === 'addCategory'">
                    <el-input
                        v-model="innerDialogForm.categoryName"
                        :maxlength="20"
                        placeholder="请填写标签类别，如职位方向、职级情况等">
                    </el-input>
                </el-form-item>
                <template v-if="innerType === 'addTag'">
                    <el-form-item
                        label="标签名称"
                        prop="tagName"
                        :rules="innerDialogFormRules.tagName">
                        <el-input
                            v-model="innerDialogForm.tagName"
                            :maxlength="20"
                            placeholder="请填写标签名称">
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        label="标签类别"
                        prop="categoryId">
                        <el-select
                            v-model="innerDialogForm.categoryId"
                            placeholder="请选择标签类别">
                            <el-option
                                :label="category.categoryName"
                                :value="category.categoryId"
                                v-for="category in categoryList"
                                :key="category.categoryId"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </template>
                <el-form-item
                    prop="categoryName"
                    label="类别名称"
                    :rules="innerDialogFormRules.categoryName"
                    v-if="innerType === 'editCategory'" >
                    <el-input
                        v-model="innerDialogForm.categoryName"
                        :maxlength="20"
                        placeholder="请填写标签类别，如职位方向、职级情况等">
                    </el-input>
                </el-form-item>
                <el-form-item
                    prop="categoryId"
                    label="移动至"
                    :rules="innerDialogFormRules.categoryId"
                    v-if="innerType === 'moveTag'">
                    <el-select
                        v-model="innerDialogForm.categoryId"
                        placeholder="请选择标签类别">
                        <el-option
                            :label="category.categoryName"
                            :value="category.categoryId"
                            v-for="category in categoryList"
                            :key="category.categoryId"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div
                class="inner-dialog-delete"
                v-if="innerType === 'deleteCategory'">
                <div class="delete-name">
                    确定删除标签类别<span class="text-primary">{{operateCategory.categoryName}}</span>，删除后不可撤销。
                </div>
            </div>
            <div
                class="inner-dialog-delete" 
                v-if="innerType === 'deleteTag'">
                <div class="delete-name">
                    确定删除标签<span class="text-primary">{{multipleTagTitle}}</span>，删除后不可撤销。
                </div>
                <div class="delete-tag-tip">
                    <span class="text-warning">注意：</span>删除标签的同时会删除对应简历上的标签。
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideInnerDialog">取消</el-button>
                <el-button type="primary" @click="confirmInnerDialog">确定</el-button>
            </span>
        </el-dialog>
        <span
            slot="footer"
            class="tag-management-footer"
            :class="{'label-management-dialog-guide': labelManagementDialogGuide && guideStep === 2}">
            <span
                class="text-operate-btn grey"
                @click="showInnerDialog('moveTag')">
                移动
            </span>
            <span
                class="text-operate-btn grey"
                @click="showInnerDialog('deleteTag')">
                删除
            </span>
            <div class="step-guide" v-if="labelManagementDialogGuide && guideStep === 2">
                <span class="step-guide-box">
                    <span class="guide-content">
                        选中标签后，可批量进行移动至其他分类、删除。
                    </span>
                    <span class="guide-btn">
                        <span class="text-operate-btn" @click="labelManagementDialogGuide = false">好的</span>
                    </span>
                </span>
                <span class="step-guide-arrows"></span>
            </div>
        </span>
    </el-dialog>
</template>

<script>
import TagService from '@src/js/service/tagService.js';

import operationPlatformService from '#/js/service/operationPlatformService.js';
import eventBus from '#/js/util/event-bus.js';



export default {
    name: 'tag-manage',
    props: {},
    data() {
        return {
            isShow: false,
            loading: false,
            categoryTags: [],
            clickTimer: null,
            categoryList: [],
            innerDialogVisible: false,
            innerDialogLoading: false,
            innerType: "",
            innerDialogForm: {
                categoryId: "",
                categoryName: "",
                tagName: ""
            },
            innerDialogFormRules: {
                categoryName: [
                    { required: true, message: "请输入标签类别" }
                ], 
                categoryId: [
                    { required: true, message: "请选择标签类别" }
                ], 
                tagName: [
                    { required: true, message: "请输入标签名称" }
                ]
            },
            operateCategory: {},
            multipleTagIds: [],
            multipleTagTitle: "",
            tagEditInput: "",
            labelManagementDialogGuide: false,
            guideStep: 1
        }
    },
    computed: {
        innerDialogTitle() {
            switch(this.innerType) {
                case 'addCategory': return '添加标签类别';
                case 'addTag': return '添加标签';
                case 'editCategory': return '编辑标签类别';
                case 'deleteCategory': return '删除标签类别';
                case 'deleteTag': return '批量删除标签';
                case 'moveTag': return '移动标签';
            }
        }
    },
    methods: {
        show() {
            this.isShow = true;
            this.getCategorytags();
            this.getCategoryList();
        },
        // 单纯获取标签类型
        getCategoryList() {
            this.categoryList = [];
            const params = {
                tagType: 1
            };
            operationPlatformService.getTagCategoryList(params)
            .then(res => {
                this.categoryList = res || [];
            });
        },
        // 获取标签类型+标签数据
        getCategorytags() {
            this.categoryTags = [];
            
            this.loading = true;
            const params = {
                tagType: 1
            };
           
            operationPlatformService.getTagList(params)
            .then(res => {
                this.categoryTags = res || [];
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        
        hideDialog() {
            this.$emit('labelManagementClose');
            this.isShow = false;
        },
        selectTag(categoryIndex, tagIndex, tag) {
            if (this.clickTimer) {
                clearTimeout(this.clickTimer);
                this.clickTimer = null;
            }
            this.clickTimer = setTimeout(() => {
                tag.selected = !tag.selected;
                let tagItems = [].concat(this.categoryTags[categoryIndex].tagItems);
                tagItems[tagIndex] = tag;
                this.$set(this.categoryTags[categoryIndex], 'tagItems', tagItems);
            }, 0);
        },
        editTag(categoryIndex, tagIndex, tag) {
            let tagItemWidth = this.$el.querySelectorAll('.tag-list')[categoryIndex].querySelectorAll('.tag-item')[tagIndex].offsetWidth;
            if (this.clickTimer) {
                clearTimeout(this.clickTimer);
                this.clickTimer = null;
            }
            this.tagEditInput = tag.tagName;
            tag.selected = false;
            tag.isEdit = true;
            let tagItems = [].concat(this.categoryTags[categoryIndex].tagItems);
            tagItems[tagIndex] = tag;
            this.$set(this.categoryTags[categoryIndex], 'tagItems', tagItems);
            this.$nextTick(() => {
                let inputVue = this.$refs['tagEditInput_' + categoryIndex + '_' + tagIndex][0];
                inputVue.$el.querySelector('.el-input__inner').style.width = tagItemWidth + 24 + 'px';
                inputVue.focus();
            })
        },
        quitTagEdit(categoryIndex, tagIndex, tag) {
            tag.isEdit = false;
            let tagItems = [].concat(this.categoryTags[categoryIndex].tagItems);
            tag.tagName = this.tagEditInput;
            tagItems[tagIndex] = tag;
            this.$set(this.categoryTags[categoryIndex], 'tagItems', tagItems);
        },
        confirmEditTag(categoryIndex, tagIndex, tag) {
            let tagInput = this.categoryTags[categoryIndex].tagItems[tagIndex].tagName;
            tagInput = tagInput.trim().replace(/\s{2}/g, ' ');
            if(!tagInput) {
                shortTips('标签名不能为空');
                return false;
            }
            if(tagInput === this.tagEditInput) {
                this.quitTagEdit(categoryIndex, tagIndex, tag);
                return false;
            }
            this.loading = true;
            
            // isCateTag = false,传tagid和tagname;
            // sCateTag = true,传cateid和catename;
            const params = {
                tagType: 1,
                isCategoryTag: false,
                id: tag.tagId,
                name: tagInput,
            };
            operationPlatformService.updateCategoryOrTag(params)
            .then(res => {
                this.tagEditInput = tagInput;
                this.loading = false;
                shortTips('标签修改成功!');
                this.quitTagEdit(categoryIndex, tagIndex, tag);

                this.editChangeFinish();
            })
            .finally(() => {
                this.loading = false;
            });
        },
        inputTag(categoryIndex, tagIndex) {
            this.$nextTick(() => {
                // 输入框宽度随文字长度增加
                let tagItemText = this.$el.querySelectorAll('.tag-list')[categoryIndex].querySelectorAll('.tag-item')[tagIndex];
                let tagItemWidth = tagItemText.offsetWidth;
                let inputVue = this.$refs['tagEditInput_' + categoryIndex + '_' + tagIndex][0];
                inputVue.$el.querySelector('.el-input__inner').style.width = tagItemWidth + 24 + 'px';
            })
        },
        showInnerDialog(type, category) {
            if(type === 'moveTag' || type === 'deleteTag') {
                let selectedTagIds = [],
                    selectedTagNames = [];
                this.categoryTags.forEach(category => {
                    for(let i = 0; i < category.tagItems.length; i++) {
                        if(category.tagItems[i].selected) {
                            selectedTagIds.push(category.tagItems[i].tagId);
                            selectedTagNames.push(category.tagItems[i].tagName);
                        }
                    }
                })
                if(selectedTagIds.length === 0) {
                    shortTips('请至少选择一个标签');
                    return false;
                }
                this.multipleTagIds = selectedTagIds;
                this.multipleTagTitle = selectedTagNames.join("、");
            }
            if(type === 'editCategory' || type === 'deleteCategory') {
                this.operateCategory = category;
                this.innerDialogForm.categoryName = category.categoryName;
            }
            this.innerType = type;
            this.innerDialogVisible = true;
        },
        hideInnerDialog() {
            this.innerDialogVisible = false;
            if(this.innerType.indexOf('delete') === -1) {
                this.$refs.innerDialogForm.clearValidate();
                this.$refs.innerDialogForm.resetFields();
            }
        },
        confirmInnerDialog() {
            if(this.innerType.indexOf('delete') === -1) {
                this.$refs.innerDialogForm.validate((valid) => {
                    if(valid) {
                        switch(this.innerType) {
                            case 'addCategory':
                                this.addTagCategory();
                                break;
                            case 'addTag':
                                this.addTag();
                                break;
                            case 'editCategory':
                                this.editCategory();
                                break;
                            case 'moveTag':
                                this.moveTag();
                                break;
                        }
                    }
                })
            } else if(this.innerType === 'deleteCategory') {
                this.deleteCategory()
            } else if(this.innerType === 'deleteTag') {
                this.deleteTag()
            }
        },

        // 添加标签分类
        addTagCategory() {
            const params = {
                tagType: 1,
                categoryName: this.innerDialogForm.categoryName,
            };

            this.innerDialogLoading = true;
            operationPlatformService.tagAddCategory(params)
            .then(res => {
                shortTips("标签类别添加成功！")
                this.hideInnerDialog();
                this.getCategoryList();
                this.getCategorytags();
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                this.innerDialogLoading = false;
            });
        },
        addTag() {
            const params = {
                tagName: this.innerDialogForm.tagName,
                categoryId: this.innerDialogForm.categoryId || "0",
                tagType: 1,
            };

            this.innerDialogLoading = true;
            operationPlatformService.tagAdd(params)
            .then(res => {
                shortTips("标签添加成功！")
                this.hideInnerDialog();
                this.getCategorytags();

                this.editChangeFinish();
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                this.innerDialogLoading = false;
            });
        },
        // mergeTag(params) {
        //     this.loading = true;
        //     TagService.mergeTag(params).then(res => {
        //         shortTips("合并成功！")
        //         this.loading = false;
        //         this.getCategorytags();
        //     }).catch(err => {
        //         this.loading = false;
        //         console.log(err)
        //     })
        // },
        editCategory() {
            this.innerDialogLoading = true;

            const params = {
                tagType: 1,
                isCategoryTag: true,
                id: this.operateCategory.categoryId,
                name: this.innerDialogForm.categoryName,
            };
            operationPlatformService.updateCategoryOrTag(params)
            .then(res => {
                shortTips("编辑标签类别成功！")
                this.innerDialogLoading = false;
                this.hideInnerDialog();
                this.getCategorytags();

                this.editChangeFinish();
            })
            .finally(() => {
                this.innerDialogLoading = false;
            });
        },
        deleteCategory() {
            const params = {
                tagType: 1,
                categoryId: this.operateCategory.categoryId,
            };
            this.innerDialogLoading = true;
            operationPlatformService.tagDeleteCategory(params)
            .then(res => {
                shortTips("标签类别已删除!");
                this.hideInnerDialog();
                this.getCategorytags();
            })
            .finally(() => {
                this.innerDialogLoading = false;
            });
        },
        moveTag() {
            const params = {
                tagType: 1,
                categoryId: this.innerDialogForm.categoryId,
                tagIds: this.multipleTagIds,
            };
            this.innerDialogLoading = true;
            operationPlatformService.batchMoveTag(params)
            .then(res => {
                shortTips("标签移动成功");
                this.hideInnerDialog();
                this.getCategorytags();

                this.editChangeFinish();
            })
            .finally(() => {
                this.innerDialogLoading = false;
            });
        },
        deleteTag() {
            const params = {
                tagType: 1,
                tagIds: this.multipleTagIds,
            };
            this.innerDialogLoading = true;
            operationPlatformService.batchTagDelete(params)
            .then(res => {
                shortTips("标签删除成功!");
                this.hideInnerDialog();
                this.getCategorytags();

                this.editChangeFinish();
            })
            .finally(() => {
                this.innerDialogLoading = false;
            });
        },
        
        // 编辑后同步数据
        editChangeFinish() {
            eventBus.$emit('tag-change-finish');
        },
       
    }
}
</script>

<style lang="scss" scope>
.tag-management-dialog.el-dialog {
    width: 580px;
    &.is-guide {
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 6px;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4000;
            background-color: rgba(0,0,0,0.5);
        }
    }
    .tag-management-title {
        display: block;
        font-size: 16px;
        color: #444;
        line-height: 52px;
        cursor: default;
        .el-icon-close {
            float: right;
            cursor: pointer;
            line-height: 52px;
            margin-left: 20px;
        }
        .add-category-tag {
            float: right;
            .text-operate-btn.text-operate-btn-hide {
                visibility: hidden;
            }

            .text-operate-btn + .text-operate-btn {
                margin-left: 12px;
            }
        }
    }
    .category-tag-list {
        max-height: 340px;
        overflow: auto;
        .category-tag-item {
            color: #666;
            line-height: 20px;
            font-size: 14px;
            .category-header {
                margin-bottom: 10px;
                .category-title {
                    margin-right: 12px;
                    font-weight: bold;
                    cursor: default;
                }
                .category-edit, .category-delete {
                    font-size: 16px;
                    cursor: pointer;
                    display: none;
                }
                .category-edit {
                    margin-right: 5px;
                }
                &:hover {
                    .category-edit, .category-delete {
                        display: inline-block;
                    }
                }
            }
            .tag-list {
                display: flex;
                flex-wrap: wrap;
                .tag-item {
                    display: inline-block;
                    height: 22px;
                    margin: 0 10px 13px 0;
                    padding: 0 6px;
                    background-color: rgba(153, 153, 153, 0.10);
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    cursor: pointer;
                    position: relative;
                    .selected-icon {
                        position: absolute;
                        right: -7px;
                        top: -7px;
                        color: #999;
                        font-size: 14px;
                    }
                    &.is-select {
                        .selected-icon {
                            color: $primary;
                            font-weight: bold;
                        }
                    }
                    &.is-edit {
                        position: absolute;
                        z-index: -1;
                        opacity: 0;
                    }
                }
                .tag-item-input.el-input {
                    width: auto;
                    margin: 0 10px 13px 0;
                    .el-input__inner {
                        padding: 0 25px 0 6px;
                        height: 22px;
                        width: auto;
                        background-color: #fff;
                        border: 1px solid #38bc9d;
                        line-height: 20px;
                        box-shadow: 0 0 2px 0 rgba(63, 188, 157, 0.3);
                    }
                    .el-input__suffix-inner {
                        cursor: pointer;
                    }
                }
                .tag-list-null {
                    margin-bottom: 13px;
                }
            }
        }
    }
    .no-tag-category {
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty-img {
            display: inline-block;
            background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
            background-size: contain;
            width: 150px;
            height: 150px;
        }
        .empty-data {
            line-height: 20px;
            margin-bottom: 0px;
        }
    }
    .el-dialog__footer {
        padding: 0 20px;
        height: 43px;
        border-top: 1px solid #ddd;
        line-height: 42px;
        .text-operate-btn + .text-operate-btn {
            margin-left: 12px;
        }
    }
    .add-category-tag,
    .tag-management-footer {
        &.label-management-dialog-guide {
            display: inline-block;
            z-index: 4001;
            position: relative;
            & > span {
                pointer-events: none;
            }
            .text-operate-btn.is-dialog-guide {
                pointer-events: none;
                background-color: #fff;
                display: inline-block;
                border-radius: 6px;
                height: 24px;
                line-height: 24px;
                padding: 0 6px;
            }
            .step-guide {
                position: absolute;
                z-index: 4001;
                left: 140px;
                top: 0;
                font-size: 14px;
                line-height: 24px;
                font-weight: normal;
                .step-guide-box {
                    position: relative;
                    display: inline-block;
                    width: 340px;
                    height: 72px;
                    border: 2px dashed rgba(0, 0, 0, 0.4);
                    background-color: #fff;
                    border-radius: 12px;
                    padding: 10px;
                }
                .guide-content {
                    color: #666;
                    line-height: 24px;
                }
                .guide-btn {
                    position: absolute;
                    bottom: 10px;
                    right: 18px;
                    .text-operate-btn + .text-operate-btn {
                        margin-left: 10px;
                    }
                }
                .step-guide-arrows {
                    position: absolute;
                    top: 27px;
                    left: -62px;
                    width: 62px;
                    height: 2px;
                    border-top: 2px dashed #fff;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 2px;
                        height: 18px;
                        background-color: #fff;
                        transform: rotate(-60deg);
                        transform-origin: top left;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 2px;
                        left: 0;
                        width: 2px;
                        height: 18px;
                        background-color: #fff;
                        transform: rotate(60deg);
                        transform-origin: bottom left;
                    }
                }
            }
        }
    }
    .add-category-tag.label-management-dialog-guide {
        .step-guide {
            left: 162px;
        }
    }
    .tag-management-footer.label-management-dialog-guide {
        background-color: #fff;
        border-radius: 6px;
        padding: 0 6px;
        height: 24px;
        margin-top: 9px;
        line-height: 24px;
        .step-guide {
            left: 152px;
            top: -15px;
        }
    }
}
</style>

<style lang="scss">
.tag-management-inner-dialog.el-dialog {
    width: 580px;
    .el-form {
        .el-input__inner {
            padding: 0 10px;
            width: 352px;
        }
    }
    .inner-dialog-delete {
        font-size: 14px;
        line-height: 20px;
        color: #444;
        .delete-name {
            margin-bottom: 20px;
        }
        .text-primary {
            color: $primary;
        }
        .text-warning {
            color: #FC7859;
        }
    }
}
</style>
