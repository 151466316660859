<template>
    <el-dialog
        :visible.sync="isShow"
        :title="isEdit ? '编辑跟进记录' : '添加跟进记录'"
        width="610px"
        class="inquiry-dialog"
        :show-close="false"
        v-loading="loading"
        :before-close="handleCancel"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        >
        <div class="inquiry-item">
            <label :class="validChecked ? 'radio-item selected' : 'radio-item'">
                <span class="radio-wrap">
                    <input 
                        type="radio" 
                        class="radio-input" 
                        name="remark" 
                        @click="handleValidSwitch(1)"
                        />
                </span>
                <span>有效跟进</span>
            </label>
            <div class="common-inquiry" v-show="validChecked">
                <el-input
                    class="remark-text"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 9}"
                    placeholder="添加一条新跟进记录"
                    v-model="inquiryContent"
                    show-word-limit
                    maxlength="200"
                ></el-input>
                <p>绑定关联职位</p>
                <el-select 
                    v-model="bindJob" 
                    placeholder="请选择" 
                    popper-class="bind-job-options" 
                    style="width: 100%;"
                    filterab
                    >
                    <el-option
                        v-for="(item, index) in jobOptions"
                        :key="'job_' + index"
                        :label="item.name"
                        :value="item.id"
                        >
                        <div class="opt-item">
                            <div class="opt-job ellipsis" v-text="item.name">产品经理</div>
                            <div class="opt-company ellipsis" v-text="item.customerName">腾讯</div>
                            <div class="opt-time">{{ item.created | date }} 发布</div>
                        </div>
                    </el-option>
                </el-select>
                
            </div>
        </div>
        <div class="inquiry-item" v-if="!isEdit">
            <label :class="invalidChecked ? 'radio-item selected':'radio-item'">
                <span class="radio-wrap">
                    <input 
                        type="radio" 
                        class="radio-input" 
                        name="remark" 
                        @click="handleValidSwitch(0)"
                        />
                </span>
                <span>无效跟进</span>
            </label>
            <el-input
                class="remark-text"
                type="textarea"
                v-show="invalidChecked"
                :autosize="{ minRows: 3, maxRows: 9}"
                placeholder="添加一条新跟进记录"
                v-model="invalidInquiryContent"
                show-word-limit
                maxlength="200"
            ></el-input>
            <!-- <ul class="invalid-list" v-show="invalidChecked">
                <li 
                    v-for="(item, index) in invalidArr" 
                    :key="index" 
                    :class="item.selected ? 'li-item selected' : 'li-item'" 
                    @click="handleItem(item, invalidArr, 'invalidText')"
                    >
                    <span>
                        {{item.text}}
                        <i class="remark" v-if="item.remark">（{{item.remark}}）</i>
                    </span>
                    <i 
                        class="el-icon-circle-close cancel-selected-icon" 
                        @click.stop="cancelSelected" 
                        v-if="item.selected"></i>
                </li>
            </ul> -->
            
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import emitter from '@src/js/mixins/emitter.js';
import operationPlatformService from '#/js/service/operationPlatformService.js';


// 无效寻访
    const invalidList = [{
    //     id:1,
    //     text:'有效通话'
    // }, {
        id:2,
        text:'未接电话'
    }, {
        id:3,
        text:'无效电话',
        remark:'空号/号码非本人'
    }, {
        id:4,
        text:'电话打不通',
        remark:'关机/停机/无法接通'
    }, {
        id:5,
        text:'暂不方便接听'
    }, {
        id:6,
        text:'拒绝与猎头沟通'
    }];
export default {
    name: 'trace-dialog',
	components: {
	},
    mixins: [emitter],
	data() {
		return {
            isShow: false,
            isEdit: false,
            loading: false,
            validChecked: false,
            invalidChecked: false,

            inquiryContent: '', // 有效内容
            invalidInquiryContent: '', // 无效内容
            title: '',

            invalidArr: [],

            jobOptions: [],
            bindJob: '',
            row: null,
            traceItem: null, // 编辑时数据缓存

        };
    },
    computed: {
    },
    filters: {
        date: function (str) {
            return moment(str).format("YYYY-MM-DD");
        },
    },
    created() {
        this.getMyJobList();
    },
	mounted() {
		this.invalidArr = invalidList.map(item => ({...item, selected: false}));
	},
	methods: {
        getMyJobList() {
            operationPlatformService.getMyPublishJobs()
            .then(res => {
                this.jobOptions = res || [];
            });
        },

        show(row, traceItem){
            this.row = row;
            this.isShow = true;

            this.inquiryContent = ''; // 有效内容
            this.invalidInquiryContent = ''; // 无效内容
            this.bindJob = '';
            this.isEdit = false;
            
            // 编辑
            if(traceItem) {
                this.traceItem = traceItem;
                this.isEdit = true;
                this.validChecked = true;

                this.inquiryContent = traceItem.content;
                this.bindJob = traceItem?.jobId;
            }
        },

        handleCancel() {
            this.isShow = false;
        },

        handleValidSwitch(type) {
            if(type == 1) {
                this.validChecked = true;
                this.invalidChecked = false;
            } else {
                this.validChecked = false;
                this.invalidChecked = true;
            }
        },

        handleConfirm() {
            if(!this.validChecked && !this.invalidChecked) {
                return shortTips(`请选取跟进记录类型!`);
            }

            this.loading = true;
            if(this.isEdit) {
                const params = {
                    id: this.traceItem.id,
                    type: this.validChecked ? 1: 0, // 0为无效
                    
                    jobId: this.bindJob,
                    content: this.validChecked ? this.inquiryContent : this.invalidInquiryContent,
                };

                operationPlatformService.updateFollowRecord(params)
                .then(res => {
                    shortTips(`“${this.row.candidateName}”的跟进记录更新成功!`);
                    this.isShow = false;
                    
                    // 同步数据
                    this.$parent.$refs['trace_' + this.row.id][0]?.coverRequest();
                })
                .finally(() => {
                    this.loading = false;
                });
            } else {
                const params = {
                    type: this.validChecked ? 1: 0, // 0为无效
                    candidateId: this.row.candidateId,
                    jobId: this.bindJob,
                    content: this.validChecked ? this.inquiryContent : this.invalidInquiryContent,
                };

                operationPlatformService.addFollowRecord(params)
                .then(res => {
                    shortTips(`“${this.row.candidateName}”的跟进记录添加成功!`);
                    this.isShow = false;
                    
                    // 同步数据
                    // 1.同步表的数据
                    this.dispatch('OperationPlatform', 'handleOperationCb', 'followRecordsCount', [this.row.id], this.row.followRecordsCount++);
                    // 2.同步列表数据
                    this.$parent.$refs['trace_' + this.row.id][0]?.coverRequest();
                })
                .finally(() => {
                    this.loading = false;
                });
            }
            
        },


        handleItem(item) {
            this.invalidArr = [{...item, selected: true}];
        },
        cancelSelected() {
            this.invalidArr = invalidList.map(item => ({...item, selected: false}));
        },
    }
}
</script>

<style lang="scss" scope>
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.inquiry-dialog {
    .el-dialog__body {
        padding: 30px 0;
    }
}
.remark-text.el-textarea{
    .el-textarea__inner {
        display: block;
        background: #FFFFFF;
        border: 1px solid #ddd;
        border-radius: 2px;
        width:100%;
        min-height: 72px;
        max-height: 272px;
        padding: 5px 10px;
        font-size: 14px;
        margin-bottom: 20px;
        &::placeholder{
            color: #aaa;
        }
        &:focus {
            border-color: #7BCCBB;
        }
    }
}

.invalid-list{
    width: 490px;
    margin-top: 8px;
    border: 1px solid #eee;
    border-radius: 4px;
    .li-item{
        height: 40px;
        padding: 0 16px;
        line-height: 40px;
        &:hover, &.selected{
            color: $primary;
            background:rgba(56,188,157,.2);
        }
        & > span:nth-of-type(1) {
            cursor: pointer;
        }
        .cancel-selected-icon {
            float: right;
            font-size: 18px;
            line-height: 40px;
            cursor: pointer;
        }
    }
}

.bind-job-options{
    width: 570px;
    .opt-item{
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        color: #666;
        .opt-job{ 
            width: 40%;
        }
        .opt-company{
            width: 36%;
        }
        .opt-time{
            width: 24%;
            color: #999;
        }
    }
}
.inquiry-item{
    font-size: 14px;
    color: #666;
    padding: 0 20px;
    .common-inquiry{
        margin-bottom: 10px;
        
        
    }
    
    .radio-wrap{
        display: inline-block;
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        vertical-align: middle;
        & + span{
            margin-left: 5px;
        }
    }
    .radio-input{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }
    .radio-item{
        width: 100%;
        color:#444;
        cursor: pointer;
        line-height: 32px;
        margin-bottom: 0;
        .radio-wrap{
            border: 1px solid #D3D3D3;
        }
        &.selected{
            color:#444;

            .radio-wrap{
                border-color: $primary;

                &:after{
                    content: '';
                    display: block;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 8px;
                    height: 8px;
                    background-color: $primary;
                    border-radius: 50%;
                }
            }

            .remark{
                color: #37BC9C;
            }
        }
        > span{
            vertical-align: middle;
        }
        .remark{
            color: #999;
        }
    }
    & + .inquiry-item {
        position: relative;
        &::before {
            content: "";
            width: calc(100% - 40px);
            height: 1px;
            background-color: #ddd;
            position: absolute;
            top: 0;
            left: 20px;
        }
    }
}
</style>
