<template>
    <div
        class="add-itap"
        :class="isBatch ? 'is-in-dialog' : ''"
        v-loading="loading"
    >
        <div
            class="choosed-itap-list"
            v-if="newChoosedItaps.length > 0"
        >
            <span
                class="itap-item choosed-itap-item"
                v-for="(itap, index) in newChoosedItaps"
                :key="index"
                v-show="itap"
                >
                <!-- {{itap}} -->
                {{itap.tagName}}
                <i
                    class="el-icon-circle-close itap-delete"
                    v-show="row.receiverName && row.receiverName == userInfo.realName"
                    @click.prevent="deleteItap(itap, index)"
                ></i>
            </span>
        </div>
        <el-button
            class="add-itap-button"
            icon="el-icon-circle-plus-outline add-itap-icon"
            @click="showAddItap"
            v-show="!isBatch && !isShowAddItap && newChoosedItaps.length > 0 
                && row.receiverName && row.receiverName == userInfo.realName"
            >
            添加
        </el-button>
        <div ref="addItapArea" v-show="isBatch || isShowAddItap || newChoosedItaps.length == 0">
            <div class="itap-defined" >
                <el-input
                    placeholder="添加新标签"
                    v-model="definedItapText"
                    :maxlength="20"
                    size="small"
                    @keyup.enter.native="definedItap"
                >
                </el-input>
                <el-button
                    type="primary"
                    class="itap-defined-btn"
                    @click="definedItap"
                >添加</el-button>
            </div>
            <div class="my-tag-list" v-if="newMyItaps.length > 0">
                <template v-if="isResume">
                    <template v-for="(category, categoryIndex) in newMyItaps">
                        <div
                            class="category-tag-item"
                            :key="categoryIndex"
                            v-if="category.tagItems && category.tagItems.length > 0">
                            <div class="category-title">
                                {{category.categoryName}}
                            </div>
                            <div class="tag-list">
                                <template >
                                    <span
                                        class="tag-item"
                                        v-for="(tag, tagIndex) in category.tagItems"
                                        :key="tagIndex"
                                        @click="chooseAddItap(tag)">
                                        {{tag.tagName}}
                                    </span>
                                </template>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <span
                        class="tag-item"
                        v-for="(tag, tagIndex) in newMyItaps"
                        :key="tagIndex"
                        @click="chooseAddItap(tag)">
                        {{tag}}
                    </span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import operationPlatformService from '#/js/service/operationPlatformService.js';
import emitter from '@src/js/mixins/emitter.js';
import eventBus from '#/js/util/event-bus.js';

export default {
    name: 'tag-add-area',
    componentName: "TagAddArea",
	components: {
	},
    mixins: [emitter],
	props: {
        isBatch: {
            //是否是在批量打标签的弹窗中
            type: Boolean,
            default: false
        },
        // tagData: {
        //     //对单条数据添加的时候需要
        //     type: Object
        // },
        tagData: {
            type: Array
        },
        tagDataIds: {
            type: Array
        },
        isResume: {
            type: Boolean,
            default: true
        },
        row: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            newChoosedItaps: [],
            definedItapText: '',
            isShowAddItap: false,
            loading: false,
            newMyItaps: [],
        }
    },
    computed: {
        newMyItaps1() {
            //存放新增的个人标签库标签
            return this.isResume ? this.$store.state.myItaps.myResumeItaps : this.$store.state.myItaps.myJobItaps
        },
        data1() {
            return this.isResume ? this.tagData.resumeListItem : this.tagData
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    watch: {
        // data: {
        //     deep: true,
        //     immediate: true,
        //     handler: function (val) {
        //         if(val && val.tags && val.tags.length > 0) {
        //             let itaps = [];
        //             val.tags.forEach((item) => {
        //                 itaps.push(item.tagName);
        //             })
        //             this.newChoosedItaps = itaps;
        //         } else {
        //             this.newChoosedItaps = [];
        //         }
        //     }
        // },
        tagData: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.newChoosedItaps = this.tagData;
            }
        },
    },
    created() {
        this.getCategorytags();
        eventBus.$on('tag-change-finish', this.tagChangeFinish);
    },
	mounted() {
	},
    
    beforeDestroy() {
        eventBus.$off('tag-change-finish', this.tagChangeFinish);
    },
	methods: {
        tagChangeFinish() {
            this.getCategorytags();
        },
        // 获取标签类型+标签数据
        getCategorytags() {
            this.newMyItaps = [];
            
            this.loading = true;
            const params = {
                tagType: 1
            };
            operationPlatformService.getTagList(params)
            .then(res => {
                this.newMyItaps = res || [];
            })
            .finally(() => {
                this.loading = false;
            });
        },
        deleteItap(itap, index) {
            if(this.isBatch) {
                //批量打标签的删除标签操作，点击删除仅删除前端数组，不发送请求
                this.newChoosedItaps.splice(index, 1);
            } else {
                this.loading = true;
                const params = {
                    tagType: 1,
                    tagId: itap.tagId,
                    objectId: this.tagDataIds[0],
                };
                operationPlatformService.tagUnbind(params)
                .then(res => {
                    shortTips(`${itap.tagName} 标签解绑定成功！`);
                    this.newChoosedItaps.splice(index, 1);

                    // 标签操作的是candidateId
                    // 删除操作同步到主表格
                    this.dispatch('OperationPlatform', 'merge-tag', {
                        label: 'businessTags', 
                        candidateIds: this.tagDataIds,
                        operateType: 'delete',
                        tagIds: [itap.tagId],
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
        deleteItap1(itap, index) {
            if(this.isBatch) {
                //批量打标签的删除标签操作，点击删除仅删除前端数组，不发送请求
                this.newChoosedItaps.splice(index, 1);
            } else {
                //单条数据的打标签，此时点击删除即发送一次删除标签请求
                if(this.isResume && this.data.resumeId) {
                    this.loading = true;
                    talentPoolService.removeItapFromResume({
                        resumeId: this.data.resumeId,
                        tagName: itap
                    }).then((res) => {
                        this.loading = false;
                        this.newChoosedItaps.splice(index, 1);
                        this.dispatch('TableWrapper', 'update-item', this.tagData);
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    })
                } else if(!this.isResume && this.data.jobId){
                    //myOrders
                    this.loading = true;
                    myOrdersService.deleteTag({
                        jobId:this.data.jobId,
                        tagName: itap
                    })
                        .then(res => {
                            this.newChoosedItaps.splice(index, 1);
                            this.loading = false;
                            this.dispatch('MyOrdersWrapper', 'update-item', this.tagData);
                        })
                        .catch(err => {
                            console.log(err);
                            this.loading = false;
                        });
                } else {
                    console.log('未传入简历ID或职位ID');
                    shortTips('系统异常，请稍后重试');
                }
            }
        },
        definedItap() {
            let itap = this.definedItapText.trim().replace(/\s{2}/g, ' ');
            if(!itap) {
                shortTips('标签名不能为空');
                return false;
            }

            let index = this.newChoosedItaps.findIndex(item => item.tagName == itap);
            if(index >= 0) {
                shortTips('该标签已存在！');
            } else {

                const params = {
                    tagName: itap,
                    categoryId: "0",
                    tagType: 1,
                };

                this.loading = true;
                operationPlatformService.tagAdd(params)
                .then(res => {
                    shortTips(`${itap} 标签添加成功！`);
                    // this.hideInnerDialog();
                    // this.getCategorytags();
                    this.definedItapText = '';
                    this.getCategorytags();
                })
                .finally(() => {
                    this.loading = false;
                });

            }
        },
        definedItap1() {
            let itap = this.definedItapText.trim().replace(/\s{2}/g, ' ');
            let index = this.newChoosedItaps.indexOf(itap);
            if(!itap) {
                shortTips('标签名不能为空');
                return false;
            }
            if(index >= 0) {
                shortTips('该标签已存在！');
            } else {
                //自定义输入的标签如果未存在于个人标签库，则需要新增到个人标签库
                if(this.isResume) {
                    let tagExist = this.newMyItaps.some((category, index) => {
                        if(category.tagItems && category.tagItems.length > 0) {
                            let tag = category.tagItems.find(tag => tag.tagName === itap);
                            if(tag) {
                                return true;
                            }
                        }
                        if(index === this.newMyItaps.length - 1) {
                            return false;
                        }
                    });
                    if(!tagExist) {
                        if(this.newMyItaps.length == 0) {
                            let newMyItaps = [{
                                tagItems: [{
                                    tagId: "",
                                    tagName: itap
                                }]
                            }]
                            this.$store.dispatch('setMyResumeItaps', newMyItaps);
                        }else {
                            let defaultCategoryIndex = this.newMyItaps.findIndex(category => category.categoryId === '-1');
                            if(defaultCategoryIndex == -1) {
                                defaultCategoryIndex = 0;
                            }
                            this.newMyItaps[defaultCategoryIndex].tagItems.unshift({
                                tagId: "",
                                tagName: itap
                            });
                        }
                        this.$store.dispatch('setMyResumeItaps', this.newMyItaps);
                    }
                } else {
                    if(this.newMyItaps.indexOf(itap) === -1) {
                        this.newMyItaps.unshift(itap);
                        this.$store.dispatch('setMyJobItaps', this.newMyItaps);
                    }
                }
                this.addItap(itap,"input");
                this.definedItapText = '';
            }
        },
        chooseAddItap(itap) {
            // let index = this.newChoosedItaps.indexOf(itap);
            let index = this.newChoosedItaps.findIndex(item => item.tagId == itap.tagId);
            if(index >= 0) {
                shortTips('该标签已存在！');
            } else {
                this.addItap(itap, "choose");
            }
        },
        addItap(tag, type){
            
            if(this.isBatch) {
                //批量打标签的增加标签操作，点击增加时仅增加前端数组，不发送请求
                this.newChoosedItaps.unshift(tag);
            } else {
                //单条数据的打标签，点击增加即发送一次增加标签请求
                this.isShowAddItap = true;
                const params = {
                    tagType: 1,
                    tagId: tag.tagId,
                    objectId: this.tagDataIds[0],
                };

                this.loading = true;
                operationPlatformService.tagBind(params)
                .then(res => {
                    this.newChoosedItaps.unshift(tag);
                    
                    shortTips(`${tag.tagName} 标签绑定成功！`);
                    // 数据同步到主表格

                    this.dispatch('OperationPlatform', 'merge-tag', {
                        label: 'businessTags', 
                        candidateIds: this.tagDataIds,
                        operateType: 'add',
                        tagIds: [{
                            id: tag.tagId,
                            candidateId: this.tagDataIds[0],
                            name: tag.tagName,
                        }],
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
        addItap1(itap, type){
            if(this.isBatch) {
                //批量打标签的增加标签操作，点击增加时仅增加前端数组，不发送请求
                this.newChoosedItaps.unshift(itap);
            } else {
                //单条数据的打标签，点击增加即发送一次增加标签请求
                this.isShowAddItap = true;
                if(this.isResume && this.data.resumeId) {
                    let resumeIds = [];
                    resumeIds.push(this.data.resumeId);
                    this.loading = true;
                    talentPoolService.addItapToResume({
                        objectIds: resumeIds,
                        tagsName: itap,
                        type: 1
                    }).then((res) => {
                        this.loading = false;
                        if(res) {
                            shortTips('添加成功');
                            this.data.tags.unshift({
                                tagId: "",
                                tagName: itap,
                                created: new Date().toJSON().slice(0, 19)
                            });
                            this.newChoosedItaps.unshift(itap);
                            //if(type == "input"){
                                this.dispatch('TableWrapper', 'refresh-search-tag', itap);
                            //}
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    })
                } else if(!this.isResume && this.data.jobId){
                    //myOrders打标签
                    this.loading = true;
                    myOrdersService.createTag({
                        objectIds:[this.data.jobId],
                        tagsName: itap,
                        type: 1
                    })
                        .then(res => {
                            if(res){
                                shortTips('添加成功');
                                this.loading = false;
                                this.data.tags.unshift({
                                    tagId: "",
                                    tagName: itap,
                                    created: new Date().toJSON().slice(0, 19)
                                });
                                this.newChoosedItaps.unshift(itap);
                                this.dispatch('MyOrdersWrapper', 'update-item', this.tagData);
                                this.dispatch('MyOrdersWrapper', 'refresh-search-tag', itap);
                            }

                        })
                        .catch(err => {
                            console.log(err);
                            this.loading = false;
                        });
                } else {
                    console.log('未传入简历ID或职位ID');
                    shortTips('系统异常，请稍后重试');
                }
            }
        },
        showAddItap() {
            this.isShowAddItap = true;
            this.$nextTick(() => {
                this.$emit('show-add-itap-area');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.add-itap {
    font-size: 14px;
    color: #666;
    .itap-item {
        padding: 0 6px;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        line-height: 20px;
        display: inline-block;
    }
    .choosed-itap-item {
        background-color: rgba(56, 188, 157, 0.1);
        color: $primary;
        cursor: default;
        position: relative;
        .itap-delete {
            font-size: 16px;
            position: absolute;
            top: -8px;
            right: -8px;
            display: none;
            cursor: pointer;

        }
        &:hover .itap-delete {
            display: inline-block;
        }
    }
    .add-itap-button {
        min-width: 62px;
        height: 20px;
        line-height: 18px;
        padding: 0 6px;
        background-color: #fff;
        border-color: $primary;
        color: $primary;
        border-radius: 4px;
        display: flex;
        align-items: center;
        &:hover, &:active, &:focus {
            background-color: $primary;
            color: #fff;
        }
    }
    .itap-defined {
        margin-top: 10px;
        line-height: 28px;
        padding-right: 18px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &-btn {
            min-width: 75px;
            margin-left: 10px;
            vertical-align: middle;
        }
    }
    &.is-in-dialog .itap-defined {
        margin-top: 20px;
    }
    .my-tag-list {
        margin-top: 20px;
        max-height: 180px;
        overflow-y: scroll;
        .category-tag-item {
            & + .category-tag-item {
                margin-top: 15px;
            }
            .category-title {
                color: #666;
                line-height: 20px;
                margin-bottom: 10px;
                font-weight: bold;
            }
            .tag-list {
                display: flex;
                flex-wrap: wrap;
            }
        }
        .tag-item {
            color: #666;
            display: inline-block;
            height: 22px;
            line-height: 22px;
            margin: 0 10px 12px 0;
            padding: 0 6px;
            background-color: rgba(153, 153, 153, 0.10);
            border: none;
            border-radius: 4px;
            cursor: pointer;
            &:hover {
                background-color: rgba(56, 188, 157, 0.1);
                color: $primary;
            }
        }
    }
}
</style>
