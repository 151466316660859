<template>
    <el-popover
        ref="itapPopover"
        placement="bottom-start"
        popper-class="itap-popover"
        width="560"
        trigger="hover"
        
        :visible-arrow="false"
        :open-delay="700"
        :offset="500"
        @after-enter="itapPopoverShow"
        @hide="itapPopoverHide"
    >

    <!-- v-if="data.tags&&data.tags.length > 0" -->
        <span slot="reference" class="talent-label">{{list[0].tagName}}</span>
        <div v-loading="loading">
            <div class="table-popper-header">
                标签
                <el-tooltip
                    placement="right-start"
                    effect="light"
                    popper-class="itap-tooltip"
                    :visible-arrow="false"
                    :open-delay="700"
                    :offset="20"
                    v-if="isResume"
                >
                    <div slot="content">
                        您了解“标签（Tag）”吗？
                        <br/>
                        标签是任由自己创造的，它是我们自己的一种分类方式。标签就是候选人的关键字、属性，帮助你快速分类查找候选人。比起传统的文件夹方式，标签管理的最大优点就是可以设定多个不同的标签第二行信息
                    </div>
                    <span class="icon-question">
                        <font-icon class="talent-icon-middle question-hover" href="#icon-talent_ql"></font-icon>
                        <font-icon class="talent-icon-middle question-no-hover" href="#icon-talent_qd"></font-icon>
                    </span>
                </el-tooltip>
                <span class="text-operate-btn blue label-management-btn" @click="showLabelManagement" v-if="isResume">标签管理</span>
            </div>
            <!-- <add-itap
                ref="addItap"
                :tagData="tagData"
                :isResume="isResume"
                @show-add-itap-area="showAddItapArea"
            ></add-itap> -->
            <tag-add-area
                ref="addItap"
                :isResume="isResume"
                :tagData="list"
                :tagDataIds="tagDataIds"
                :row="row"
                />
        </div>
    </el-popover>
</template>

<script>
import TagAddArea from './tag-add-area.vue';
import emitter from '@src/js/mixins/emitter.js';

export default {
    name: 'tag-popover',
    componentName: "TagPopover",
	components: {
        TagAddArea,
	},
    mixins: [emitter],
    props: {
        isResume: {
            type: Boolean,
            default: true
        },
        tagData: {
            type: Array
        },
        tagDataIds: {
            type: Array
        },
        row: {
            type: Object,
            default: () => {},
        },
    },
	data() {
		return {
            loading: false,
            // tags: [
            //     {tagName: 'hhhhIIII', }
            // ],
            // list: [],
            // isResume: true,
        };
    },
    computed: {
        list() {
            return this.tagData.map(item => ({
                candidateId: item.candidateId,
                tagId: item.id,
                tagName: item.name,
            }));
        },
    },
    created() {
    },
	mounted() {
	},
	methods: {
        itapPopoverShow() {},
        itapPopoverHide() {},
        
        showLabelManagement() {
            // this.hideDialog();
            this.$emit('show-tag-management');
        },
        showAddItapArea() {},
    }
}
</script>
<style lang="scss" scoped>
.talent-label {
    display: inline-block;
    margin-top: 8px;
    height: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    padding: 0 6px;
    color: $primary;
    background-color: rgba(56,188,157,0.1);
    border-radius: 4px;
}

.table-popper-header span {
    font-size: 14px;
    color: #4A90E2;
    margin-left: 6px;
    cursor: pointer;
}
</style>