var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "candidate-operation" },
    [
      _vm.row.receiverName && _vm.row.receiverName == _vm.userInfo.realName
        ? _c(
            "el-dropdown",
            { attrs: { placement: "bottom-start" } },
            [
              _c("span", { staticClass: "el-dropdown-link name-cell" }, [
                _c(
                  "span",
                  { staticClass: "operation-icon" },
                  [
                    _c("font-icon", {
                      staticClass:
                        "table-body-icon operation-icon-default tip-margin",
                      attrs: { href: "#icon-ic_act_grey" },
                    }),
                    _c("font-icon", {
                      staticClass:
                        "table-body-icon operation-icon-hover tip-margin",
                      attrs: { href: "#icon-ic_act_green" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "shrink-dropdown-menu",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.operation(0)
                          },
                        },
                      },
                      [_vm._v("转移")]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.operation(1)
                          },
                        },
                      },
                      [_vm._v("打标签")]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.operation(2)
                          },
                        },
                      },
                      [_vm._v("填写跟进记录")]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }