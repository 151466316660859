var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "show-close": false,
        visible: _vm.isShow,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": _vm.labelManagementDialogGuide
          ? "tag-management-dialog is-guide"
          : "tag-management-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "tag-management-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _vm._v("\n        编辑标签\n        "),
          _c(
            "span",
            { staticStyle: { "font-size": "12px", "margin-left": "5px" } },
            [_vm._v("双击可编辑标签名称")]
          ),
          _c("i", {
            staticClass: "el-icon-close",
            on: { click: _vm.hideDialog },
          }),
          _c(
            "span",
            {
              staticClass: "add-category-tag",
              class: {
                "label-management-dialog-guide":
                  _vm.labelManagementDialogGuide && _vm.guideStep === 1,
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-operate-btn",
                  class: {
                    "is-dialog-guide":
                      _vm.labelManagementDialogGuide && _vm.guideStep === 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showInnerDialog("addCategory")
                    },
                  },
                },
                [_vm._v("\n                添加标签类别\n            ")]
              ),
              _c(
                "span",
                {
                  staticClass: "text-operate-btn",
                  class:
                    _vm.labelManagementDialogGuide && _vm.guideStep === 1
                      ? "text-operate-btn-hide"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.showInnerDialog("addTag")
                    },
                  },
                },
                [_vm._v("\n                添加标签\n            ")]
              ),
              _vm.labelManagementDialogGuide && _vm.guideStep === 1
                ? _c("div", { staticClass: "step-guide" }, [
                    _c("span", { staticClass: "step-guide-box" }, [
                      _c("span", { staticClass: "guide-content" }, [
                        _vm._v(
                          "\n                        标签新增分类功能，点击添加类别，进行标签分类。\n                    "
                        ),
                      ]),
                      _c("span", { staticClass: "guide-btn" }, [
                        _c(
                          "span",
                          {
                            staticClass: "text-operate-btn",
                            on: {
                              click: function ($event) {
                                _vm.guideStep = 2
                              },
                            },
                          },
                          [_vm._v("下一步")]
                        ),
                      ]),
                    ]),
                    _c("span", { staticClass: "step-guide-arrows" }),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm.categoryTags.length > 0
        ? _c(
            "div",
            { staticClass: "category-tag-list" },
            [
              _vm._l(_vm.categoryTags, function (category, categoryIndex) {
                return [
                  !(
                    category.categoryId === "0" &&
                    category.tagItems &&
                    category.tagItems.length === 0
                  )
                    ? _c(
                        "div",
                        {
                          key: categoryIndex,
                          staticClass: "category-tag-item",
                        },
                        [
                          _c("div", { staticClass: "category-header" }, [
                            _c("span", { staticClass: "category-title" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(category.categoryName) +
                                  "\n                    "
                              ),
                            ]),
                            category.categoryId !== "0"
                              ? _c("i", {
                                  staticClass: "category-edit el-icon-edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showInnerDialog(
                                        "editCategory",
                                        category
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            category.tagItems.length === 0 &&
                            category.categoryId !== "0"
                              ? _c("i", {
                                  staticClass: "category-delete el-icon-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showInnerDialog(
                                        "deleteCategory",
                                        category
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "tag-list" },
                            [
                              category.tagItems.length > 0
                                ? _vm._l(
                                    category.tagItems,
                                    function (tag, tagIndex) {
                                      return _c(
                                        "span",
                                        {
                                          key: tagIndex,
                                          staticClass: "tag-item-container",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "tag-item",
                                              class: {
                                                "is-select": tag.selected,
                                                "is-edit": tag.isEdit,
                                              },
                                              attrs: {
                                                title: "双击可编辑标签名称",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectTag(
                                                    categoryIndex,
                                                    tagIndex,
                                                    tag
                                                  )
                                                },
                                                dblclick: function ($event) {
                                                  return _vm.editTag(
                                                    categoryIndex,
                                                    tagIndex,
                                                    tag
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(tag.tagName) +
                                                  "\n                                "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-check selected-icon",
                                              }),
                                            ]
                                          ),
                                          category.tagItems[tagIndex].isEdit
                                            ? _c(
                                                "el-input",
                                                {
                                                  ref:
                                                    "tagEditInput_" +
                                                    categoryIndex +
                                                    "_" +
                                                    tagIndex,
                                                  refInFor: true,
                                                  staticClass: "tag-item-input",
                                                  attrs: { maxlength: 20 },
                                                  on: {
                                                    input: (val) =>
                                                      _vm.inputTag(
                                                        categoryIndex,
                                                        tagIndex
                                                      ),
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return ((ev) =>
                                                        _vm.confirmEditTag(
                                                          categoryIndex,
                                                          tagIndex,
                                                          tag
                                                        )).apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: tag.tagName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        tag,
                                                        "tagName",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "tag.tagName",
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "el-icon-check",
                                                    attrs: { slot: "suffix" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.confirmEditTag(
                                                          categoryIndex,
                                                          tagIndex,
                                                          tag
                                                        )
                                                      },
                                                    },
                                                    slot: "suffix",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _c("span", { staticClass: "tag-list-null" }, [
                                    _vm._v(
                                      "\n                        暂无标签\n                    "
                                    ),
                                  ]),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.categoryTags.length === 0
        ? _c("div", { staticClass: "no-tag-category" }, [
            _c("span", { staticClass: "empty-img" }),
            _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.innerDialogLoading,
              expression: "innerDialogLoading",
            },
          ],
          attrs: {
            title: _vm.innerDialogTitle,
            "show-close": false,
            "custom-class": "tag-management-inner-dialog",
            visible: _vm.innerDialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerDialogVisible = $event
            },
          },
        },
        [
          _vm.innerType.indexOf("delete") === -1
            ? _c(
                "el-form",
                {
                  ref: "innerDialogForm",
                  attrs: {
                    model: _vm.innerDialogForm,
                    "label-width": "80px",
                    size: "mini",
                  },
                },
                [
                  _vm.innerType === "addCategory"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "categoryName",
                            label: "类别名称",
                            rules: _vm.innerDialogFormRules.categoryName,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: 20,
                              placeholder:
                                "请填写标签类别，如职位方向、职级情况等",
                            },
                            model: {
                              value: _vm.innerDialogForm.categoryName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.innerDialogForm,
                                  "categoryName",
                                  $$v
                                )
                              },
                              expression: "innerDialogForm.categoryName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.innerType === "addTag"
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "标签名称",
                              prop: "tagName",
                              rules: _vm.innerDialogFormRules.tagName,
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: 20,
                                placeholder: "请填写标签名称",
                              },
                              model: {
                                value: _vm.innerDialogForm.tagName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.innerDialogForm, "tagName", $$v)
                                },
                                expression: "innerDialogForm.tagName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "标签类别", prop: "categoryId" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择标签类别" },
                                model: {
                                  value: _vm.innerDialogForm.categoryId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.innerDialogForm,
                                      "categoryId",
                                      $$v
                                    )
                                  },
                                  expression: "innerDialogForm.categoryId",
                                },
                              },
                              _vm._l(_vm.categoryList, function (category) {
                                return _c("el-option", {
                                  key: category.categoryId,
                                  attrs: {
                                    label: category.categoryName,
                                    value: category.categoryId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.innerType === "editCategory"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "categoryName",
                            label: "类别名称",
                            rules: _vm.innerDialogFormRules.categoryName,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: 20,
                              placeholder:
                                "请填写标签类别，如职位方向、职级情况等",
                            },
                            model: {
                              value: _vm.innerDialogForm.categoryName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.innerDialogForm,
                                  "categoryName",
                                  $$v
                                )
                              },
                              expression: "innerDialogForm.categoryName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.innerType === "moveTag"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "categoryId",
                            label: "移动至",
                            rules: _vm.innerDialogFormRules.categoryId,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择标签类别" },
                              model: {
                                value: _vm.innerDialogForm.categoryId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.innerDialogForm,
                                    "categoryId",
                                    $$v
                                  )
                                },
                                expression: "innerDialogForm.categoryId",
                              },
                            },
                            _vm._l(_vm.categoryList, function (category) {
                              return _c("el-option", {
                                key: category.categoryId,
                                attrs: {
                                  label: category.categoryName,
                                  value: category.categoryId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.innerType === "deleteCategory"
            ? _c("div", { staticClass: "inner-dialog-delete" }, [
                _c("div", { staticClass: "delete-name" }, [
                  _vm._v("\n                确定删除标签类别"),
                  _c("span", { staticClass: "text-primary" }, [
                    _vm._v(_vm._s(_vm.operateCategory.categoryName)),
                  ]),
                  _vm._v("，删除后不可撤销。\n            "),
                ]),
              ])
            : _vm._e(),
          _vm.innerType === "deleteTag"
            ? _c("div", { staticClass: "inner-dialog-delete" }, [
                _c("div", { staticClass: "delete-name" }, [
                  _vm._v("\n                确定删除标签"),
                  _c("span", { staticClass: "text-primary" }, [
                    _vm._v(_vm._s(_vm.multipleTagTitle)),
                  ]),
                  _vm._v("，删除后不可撤销。\n            "),
                ]),
                _c("div", { staticClass: "delete-tag-tip" }, [
                  _c("span", { staticClass: "text-warning" }, [
                    _vm._v("注意："),
                  ]),
                  _vm._v(
                    "删除标签的同时会删除对应简历上的标签。\n            "
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.hideInnerDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmInnerDialog },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "tag-management-footer",
          class: {
            "label-management-dialog-guide":
              _vm.labelManagementDialogGuide && _vm.guideStep === 2,
          },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "span",
            {
              staticClass: "text-operate-btn grey",
              on: {
                click: function ($event) {
                  return _vm.showInnerDialog("moveTag")
                },
              },
            },
            [_vm._v("\n            移动\n        ")]
          ),
          _c(
            "span",
            {
              staticClass: "text-operate-btn grey",
              on: {
                click: function ($event) {
                  return _vm.showInnerDialog("deleteTag")
                },
              },
            },
            [_vm._v("\n            删除\n        ")]
          ),
          _vm.labelManagementDialogGuide && _vm.guideStep === 2
            ? _c("div", { staticClass: "step-guide" }, [
                _c("span", { staticClass: "step-guide-box" }, [
                  _c("span", { staticClass: "guide-content" }, [
                    _vm._v(
                      "\n                    选中标签后，可批量进行移动至其他分类、删除。\n                "
                    ),
                  ]),
                  _c("span", { staticClass: "guide-btn" }, [
                    _c(
                      "span",
                      {
                        staticClass: "text-operate-btn",
                        on: {
                          click: function ($event) {
                            _vm.labelManagementDialogGuide = false
                          },
                        },
                      },
                      [_vm._v("好的")]
                    ),
                  ]),
                ]),
                _c("span", { staticClass: "step-guide-arrows" }),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }