<template>
    <div class="operation-platform-container">

        <top-wrap
            :total="page.total"
            :filterIndex="filterIndex"
            :filter-store="filterStore"
            :title-store="titleStore"
            :header-filter="headerFilter"
            :default-month="defaultMonth"
            @top-data-change-cb="topDataChange"
            @title-change-cb="titleChange"
            @clean-head-filter="handelCleanHeadFilter"
        />

        <!-- 运营平台列表 -->
        <operation-platform-table 
            ref="operationPlatformTableWrap"
            :title-store="titleStore"
            :table-data="tableData"
            :table-data-loading="tableDataLoading"
            @selection-change="handleSelectionChange"
            @operation-cb="handleOperationCb"
        />

        <table-page-tab
            :filterIndex="filterIndex"
            :pageTabs="pageTabJson"
            :in-tab="false"
        ></table-page-tab>
        <!-- :unApplyNumber="unApplyNumber" -->

        <div class="work-table-footer">
            <div class="footer-left">
                <div class="footer-select">
                    <input hidden v-model="allSelect" type="checkbox">
                    <span class="all-select-checkbox" @click="handleAllSelect"></span>
                    <span>全选</span>
                    <span class="select-count">已选择
                        <span class="color-orange">{{selectCount}}</span>
                        位候选人
                    </span>

                </div>
                <button
                    class="work-table-btn work-table-btn-primary"
                    :disabled="!canTag"
                    @click="handleBatchTag"
                    >
                    打标签
                </button>
                <button
                    class="work-table-btn work-table-btn-orange"
                    :disabled="!canClaim"
                    @click="handleBatchClaim"
                    >
                    认领
                </button>
            </div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.current + 1"
                :page-sizes="[20, 30, 50]"
                :page-size="page.size"
                class="el-pagination-workTable"
                layout="total, sizes, prev, pager, next, slot"
                :total="page.total">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>

        </div>


    </div>
</template>

<script>
import moment from 'moment';


import TopWrap from './component/top-wrap.vue';
import OperationPlatformTable from './layout/operation-platform-table.vue';
import TablePageTab from '#/component/workTable/table-page-tab.vue';


import operationPlatformService from '#/js/service/operationPlatformService.js';

const lastMonth = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss");
const pre2Month = moment().subtract(2, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss");
const pre3Month = moment().subtract(4, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss");
const pre6Month = moment().subtract(6, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss");

export default {
    name: 'OperationPlatform',
    // name: 'operation-platform',
    componentName: "OperationPlatform",
	components: {
        TopWrap,
        OperationPlatformTable,
        TablePageTab,
	},
	data() {
		return {
            tableType: 'operationPlatform',
            labelList: {
                    // labelList 内标准格式为{
                    //     text: 'xxxx',
                    //     .......
                    // }
                    
                    receiverName: [],   // 认领人
                    jobCategoryName: [],   // 赛道
                    businessTags: [],   // 标签
            },
           
            headerFilter: {
                receiverName: [],   // 认领人
                jobCategoryName: [],   // 赛道
                businessTags: [],   // 标签
            },
            filterStore: ['receiverName', 'jobCategoryName', 'businessTags'],
            titleStore: [],
            tableData: [],
            tableDataLoading: false,
            multipleSelection: [],
            page: {
                size: 20,
                current: 0,
                total: 0,
            },
            defaultMonth: [pre6Month ,pre3Month],

            filterIndex: 0, // 0为平台运营，1为运营数据
            pageTabJson: [
                    {
                        enTitle: "Operation Platform",
                        cnTitle: "平台运营",
                        path: "/operationPlatform",
                        title: "平台运营",
                        tabIndex: 0,
                        aliveComponent: 'OperationPlatform'
                    }, 
                    {
                        enTitle: "Operation Platform",
                        cnTitle: "平台运营",
                        path: "/operationPlatform/operationData",
                        title: "运营数据",
                        tabIndex: 1,
                        aliveComponent: 'OperationData'
                    }
                ],

            pagerJump: 0,

            cacheArgs: null,
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        userId() {
            return this.userInfo.id;
        },
        // 运营平台权限
        isPlatformOperations() {
            return this.userInfo.privilegeCodeList?.includes("PlatformOperations");
        },
        allSelect(){
            return this.tableData.length > 0 ? this.tableData.length == this.multipleSelection.length : false;
        },
        // 能认领
        canClaim() {
            return this.multipleSelection.length> 0 && this.multipleSelection.every(item => !item.receiverName);
        },
        // 能打标
        canTag() {
            return this.multipleSelection.length> 0 && this.multipleSelection.every(item => {
                return item.receiverName !='' && item.receiverName == this.userInfo.realName;
            });
        },
        selectCount() {
            return this.multipleSelection.filter(item => item.candidateName).length;
        },

    },
    created() {
        this.requestFilterData();
    },
	mounted() {
        this.initTitleStore();
        this.requestListData();

        this.$on('merge-tag', params => {
            this.mergeTagsDateToTable(params);
        });
	},
	methods: {
        initTitleStore() {
            const defaultTitleStore = ['candidateName', 'company', 'title', 'recommendedAt', 'customerName', 'jobName', 'jobCategoryName','recommendationStatus', 'ownerName', 'candidateCreator', 'receiverName', 'businessTags', 'followRecordsCount'];

            this.titleStore = localStorage.getItem(`${this.tableType}TableTitleNew`) ? 
            JSON.parse(localStorage.getItem(`${this.tableType}TableTitleNew`)) : defaultTitleStore;
        },

        // 处理缓存的筛选数据
        filterCacheData(originData, typeName) {
            return originData.map(item => {
                if(this.cacheArgs) {
                    const _isInclude = this.cacheArgs[typeName]?.includes(item.itemValue);
                    if(_isInclude) {
                        return {
                            ...item,
                            label: item.itemText,
                            isSelect:true,
                        };
                    } else {
                        return {
                            ...item,
                            label: item.itemText,
                        };
                    }
                } else {
                    return {
                        ...item,
                        label: item.itemText,
                    };
                }
            });
        },

        // 获取筛选条件数据
        requestFilterData(args) {
            const params = {
                startDate: args?.startDate || pre6Month,
                endDate: args?.endDate || pre3Month,
                userId: args?.userId || '',

                receiverIds: args?.receiverIds || [],
                jobCategoryIds: args?.jobCategoryIds || [],
                businessTagIds: args?.businessTagIds || [],
            };
            // filterStore: ['receiverName', 'jobCategoryName', 'businessTags']

            operationPlatformService
            .getSnapshotJobCategories(params)
            .then(res => {
                // const _jobCategory = res.map(item => ({
                //     ...item,
                //     label: item.itemText,
                // }));
                const _jobCategory = this.filterCacheData(res, 'jobCategoryIds');

                this.$set(this.headerFilter, 'jobCategoryName', _jobCategory);
            });
            

            operationPlatformService
            .getReceivers(params)
            .then(res => {
                const _receiver = this.filterCacheData(res, 'receiverIds');

                this.$set(this.headerFilter, 'receiverName', _receiver);
            });


            operationPlatformService
            .getSnapshotTages(params)
            .then(res => {
                // const _tag = res.map(item => ({
                //     ...item,
                //     label: item.itemText,
                // }));
                const _tag = this.filterCacheData(res, 'businessTagIds');
                
                this.$set(this.headerFilter, 'businessTags', _tag);
            });
        },

        requestListData(args) {
            let params = {
                startDate: args?.startDate || pre6Month,
                endDate: args?.endDate || pre3Month,
                userId: args?.userId || '',
                start: (+this.page.current)*(+this.page.size),
                take: this.page.size,

                receiverIds: args?.receiverIds || [],
                jobCategoryIds: args?.jobCategoryIds || [],
                businessTagIds: args?.businessTagIds || [],
            };

            this.tableDataLoading = true;
            
            operationPlatformService
            .getSnapshotList(params)
            .then(res => {
                this.tableData = [];
                
                res.list.forEach(item => {
                    if(item.recommendations.length <2) {
                        this.tableData.push({
                            ...item,
                            ...item.recommendations[0],
                        });
                    } else {
                        item.recommendations.forEach((subItem, index) => {
                            if(index == 0) {
                                this.tableData.push({
                                    ...item,
                                    ...item.recommendations[0],
                                    rowspan: item.recommendations.length,
                                });
                            } else {
                                this.tableData.push({
                                    ...subItem,
                                    rowspan: 0,
                                    });
                            }
                        });
                    }
                })

                this.page.total = res.total;
            })
            .finally(() => {
                this.tableDataLoading = false;
                this.scrollTop();
                
                const _self = this;
                this.$nextTick(() => {
                    _self.$refs.operationPlatformTableWrap.$refs.operationPlatformTable.doLayout();
                });
            });
        },

        handleBatchClaim() {
            const _names = this.multipleSelection.map(item => item.candidateName).join(',');
           
            this.$confirm(`确认是否指认领候选人“${_names}”？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                })
                .then(() => {
                    const params = {
                        SnapshotIds: this.multipleSelection.map(item => item.id),
                    };
                    operationPlatformService.batchAcceptCandidate(params).then(res => {
                        // 将数据merge进去
                        this.mergeDateToTable('receiverName', this.multipleSelection.map(item => item.id), this.userInfo.realName);

                        shortTips(`成功认领候选人“${_names}”`);
                    });
                })
                .catch(() => {});
        },

        mergeDateToTable(label, ids, param) {
            ids.forEach(item => {
                const idIndex = this.tableData.findIndex(tItem => tItem.id == item);
              
                this.tableData[idIndex][label] = param;
            });
        },

        // 表操作的回调
        handleOperationCb(label, ids, param) {
            
            switch(label) {
                case 'receiverName':
                    this.mergeDateToTable(label, ids, param || this.userInfo.realName);
                    break;

                case 'followRecordsCount':
                    this.mergeDateToTable(label, ids, param || this.userInfo.realName);
                    break;
                case 'businessTags':
                    // this.mergeTagsDateToTable(label, ids, param.type, param.ids);
                    break;
                default:
                    break;
            }
        },
        // 标签数据合并到表格
        mergeTagsDateToTable(params) {
          
            const {label, candidateIds, operateType, tagIds} = params;

            if(operateType === 'delete') {
                const idIndex = this.tableData.findIndex(tItem => tItem.candidateId == candidateIds[0]);
                const tagIndex = this.tableData[idIndex][label].findIndex(subItem => subItem.id == tagIds[0]);
                this.tableData[idIndex][label].splice(tagIndex, 1);
            }

            if(operateType === 'add') {
                const idIndex = this.tableData.findIndex(tItem => tItem.candidateId == candidateIds[0]);
                this.tableData[idIndex][label].unshift(tagIds[0]);
            }

            if(operateType === 'batchAdd') {
                candidateIds.forEach(item => {
                    const idIndex = this.tableData.findIndex(tItem => tItem.candidateId == item);

                    tagIds.forEach(tagItem => {
                        const tagInTableIndex = this.tableData[idIndex][label].findIndex(iItem => tagItem.id == iItem.id);
                        if(tagInTableIndex < 0) {
                            this.tableData[idIndex][label].push({
                                candidateId: item,
                                ...tagItem,
                            });
                        }
                    });
                    // this.tableData[idIndex][label] = [ ..._tagIds, ...this.tableData[idIndex][label]];
                })
            }
        },

        topDataChange(params, requestFilter = false) {
            // 缓存参数
            this.cacheArgs = params;

            if(requestFilter) {
                this.requestFilterData(params);
            }
            this.page.current = 0;
            this.requestListData(params);
        },

        titleChange(val) {
            this.titleStore = val;
            localStorage.setItem(`${this.tableType}TableTitleNew`, JSON.stringify(val));
        },

        handleAllSelect() {
            this.$refs.operationPlatformTableWrap.$refs.operationPlatformTable.toggleAllSelection();
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        // 清理已选标签
        handelCleanHeadFilter (label){
            const list = this.headerFilter[label] || [];
            for (let key in list){
                list[key].isSelect = false;
            }
        },

        handleSizeChange(val) {
            this.page.current = 0;
            this.page.size = val;
            this.requestListData(this.cacheArgs);
        },
        handleCurrentChange(val) {
            this.page.current = +val-1;
            this.requestListData(this.cacheArgs);
        },
        handlePagerJump() {
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.page.total/this.page.size);

            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager)
            }
        },
        
        // 批量打标签
        handleBatchTag() {
            const _names = this.multipleSelection.map(item => item.candidateName).join(',');
           
            this.$confirm(`确认是否给候选人“${_names}”打标签？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                })
                .then(() => {
                    // const params = {
                    //     SnapshotIds: this.multipleSelection.map(item => item.id),
                    // };
                    // operationPlatformService.batchAcceptCandidate(params).then(res => {
                    //     // 将数据merge进去
                    //     this.mergeDateToTable('receiverName', this.multipleSelection.map(item => item.id), this.userInfo.realName);

                    //     shortTips(`成功认领候选人“${_names}”`);
                    // });
                    this.$refs['operationPlatformTableWrap'].batchTagOperation(this.multipleSelection.map(item => item.candidateId));
                })
                .catch(() => {});
        },
        scrollTop() {
            // this.$refs.operationPlatformTableWrap
            // this.$refs.multipleTable && (this.$refs.multipleTable.bodyWrapper.scrollTop = 0);
            setTimeout(() => {
                document.querySelector('.el-table__body-wrapper').scrollTo({ 
                    top: 0, 
                    behavior: "smooth" 
                });
            }, 50);
        },
    },
}
</script>
<style lang="scss" scope>
.color-orange{
    color: $orange;
}
.cursor-pointer{
    cursor: pointer;
}
.operation-platform-container{
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
    
    // 底部
    .work-table-footer{
        overflow: hidden;
        margin: 20px 0;
    }
    .work-table-footer .footer-left .work-table-btn {
        display: inline-block;
        height: 28px;
        width: 84px;
        margin-right: 10px;
        border-radius: 4px;
        color: #fff;
        border: none;
        outline: none;

        &.work-table-btn-orange {
            background-color: #FC7859;
            &:hover {
                background-color: #EE6E4F;
            }
        }

        &.work-table-btn-primary {
            background-color: $primary;
            &:hover {
                background-color: #36B495;
            }
        }

        &[disabled] {
            background-color: #F8F8F8;
            border: 1px solid #DDDDDD;
            color: #999999;
            cursor: not-allowed;
            &:hover {
                background-color: #F8F8F8;
            }
        }
    }
}
</style>