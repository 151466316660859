import { render, staticRenderFns } from "./tag-add-area.vue?vue&type=template&id=47ed10ea&scoped=true&"
import script from "./tag-add-area.vue?vue&type=script&lang=js&"
export * from "./tag-add-area.vue?vue&type=script&lang=js&"
import style0 from "./tag-add-area.vue?vue&type=style&index=0&id=47ed10ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ed10ea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47ed10ea')) {
      api.createRecord('47ed10ea', component.options)
    } else {
      api.reload('47ed10ea', component.options)
    }
    module.hot.accept("./tag-add-area.vue?vue&type=template&id=47ed10ea&scoped=true&", function () {
      api.rerender('47ed10ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/operation-platform/component/tag-add-area.vue"
export default component.exports