var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "operation-platform-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          ref: "operationPlatformTable",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            height: "400",
            "row-key": "rowKey",
            "tooltip-effect": "light",
            data: _vm.tableData,
            "span-method": _vm.objectSpanMethod,
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "expand-change": _vm.handleExpandChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              width: "42",
              align: "center",
              "header-align": "center",
              type: "selection",
            },
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              align: "left",
              width: "116",
              prop: "candidateName",
              label: "候选人姓名",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "candidateName-cell ellipsis",
                        attrs: {
                          title: row.candidateName,
                          target: "_blank",
                          href: `/#/candidateDetail/${row.candidateId}`,
                        },
                      },
                      [_vm._v(_vm._s(row.candidateName))]
                    ),
                    _c("candidate-operation", {
                      attrs: { row: row },
                      on: { "operation-callback": _vm.handleOperationCallback },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              align: "left",
              width: "140",
              prop: "company",
              label: "公司",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              align: "left",
              width: "140",
              prop: "title",
              label: "职位",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._l(_vm.titleStore.slice(3), function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                "header-align": "left",
                label: _vm.tableTitleMap[item].label,
                "min-width": _vm.tableTitleMap[item].minWidth,
                "class-name": _vm.tableTitleMap[item].customClass,
                "show-overflow-tooltip":
                  _vm.tableTitleMap[item].showOverflowTooltip,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item == "recommendedAt"
                          ? [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm._f("formatDate")(row[item])) +
                                  "\n                "
                              ),
                            ]
                          : item == "recommendationStatus"
                          ? [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.operationStatus[row[item]]) +
                                  "\n                "
                              ),
                            ]
                          : item == "receiverName"
                          ? [
                              row[item]
                                ? [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(row[item]) +
                                        "\n                    "
                                    ),
                                  ]
                                : _c(
                                    "span",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.claimOperation(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-icon", {
                                        staticClass:
                                          "table-body-icon-big icon-inquiry",
                                        attrs: { href: "#icon-renling" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "color-warn" },
                                        [_vm._v(" 认领")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          : item == "businessTags"
                          ? [
                              row.businessTags.length > 0
                                ? _c("tag-popover", {
                                    attrs: {
                                      tagData: row.businessTags,
                                      tagDataIds: [row.candidateId],
                                      row: row,
                                    },
                                    on: {
                                      "show-tag-management": _vm.showTagManage,
                                    },
                                  })
                                : _vm._e(),
                              row.businessTags.length == 0 &&
                              row.receiverName &&
                              row.receiverName == _vm.userInfo.realName
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.tagOperation(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-icon", {
                                        staticClass:
                                          "table-body-icon-big icon-inquiry",
                                        attrs: { href: "#icon-talent_lable" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "color-warn" },
                                        [_vm._v(" 添加")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : item == "followRecordsCount"
                          ? [
                              row.followRecordsCount > 0
                                ? _c("trace-log", {
                                    ref: "trace_" + row.id,
                                    refInFor: true,
                                    attrs: { row: row },
                                    on: {
                                      "trace-opertaion-cb":
                                        _vm.handleTraceOpertaionCb,
                                    },
                                  })
                                : _vm._e(),
                              row.followRecordsCount == 0 &&
                              row.receiverName &&
                              row.receiverName == _vm.userInfo.realName
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.traceOperation(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-icon", {
                                        staticClass:
                                          "table-body-icon-big icon-inquiry",
                                        attrs: { href: "#icon-track-red" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "color-warn" },
                                        [_vm._v(" 添加")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(row[item]) +
                                  "\n                "
                              ),
                            ],
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("template", { slot: "empty" }, [
            _c("div", [
              _c("span", { staticClass: "empty-img" }),
              _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "360px",
            title: "请选择转移给到的人员",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            visible: _vm.claimDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.claimDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "claimForm",
              attrs: { model: _vm.claimForm, rules: _vm.claimRules },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.claimForm.person,
                        callback: function ($$v) {
                          _vm.$set(_vm.claimForm, "person", $$v)
                        },
                        expression: "claimForm.person",
                      },
                    },
                    _vm._l(_vm.personList, function (item, index) {
                      return _c("el-option", {
                        key: "persion_" + index,
                        attrs: {
                          label: item.realName + "@" + item.nickName,
                          value: item.userId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.claimDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleTransformConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("trace-dialog", { ref: "traceDialog" }),
      _c("tag-dialog", {
        ref: "tagDialog",
        on: { "show-tag-management": _vm.showTagManage },
      }),
      _c("tag-manage", { ref: "tagManage" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }