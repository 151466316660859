var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "operation-platform-container" },
    [
      _c("top-wrap", {
        attrs: {
          total: _vm.page.total,
          filterIndex: _vm.filterIndex,
          "filter-store": _vm.filterStore,
          "title-store": _vm.titleStore,
          "header-filter": _vm.headerFilter,
          "default-month": _vm.defaultMonth,
        },
        on: {
          "top-data-change-cb": _vm.topDataChange,
          "title-change-cb": _vm.titleChange,
          "clean-head-filter": _vm.handelCleanHeadFilter,
        },
      }),
      _c("operation-platform-table", {
        ref: "operationPlatformTableWrap",
        attrs: {
          "title-store": _vm.titleStore,
          "table-data": _vm.tableData,
          "table-data-loading": _vm.tableDataLoading,
        },
        on: {
          "selection-change": _vm.handleSelectionChange,
          "operation-cb": _vm.handleOperationCb,
        },
      }),
      _c("table-page-tab", {
        attrs: {
          filterIndex: _vm.filterIndex,
          pageTabs: _vm.pageTabJson,
          "in-tab": false,
        },
      }),
      _c(
        "div",
        { staticClass: "work-table-footer" },
        [
          _c("div", { staticClass: "footer-left" }, [
            _c("div", { staticClass: "footer-select" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.allSelect,
                    expression: "allSelect",
                  },
                ],
                attrs: { hidden: "", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.allSelect)
                    ? _vm._i(_vm.allSelect, null) > -1
                    : _vm.allSelect,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.allSelect,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.allSelect = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.allSelect = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.allSelect = $$c
                    }
                  },
                },
              }),
              _c("span", {
                staticClass: "all-select-checkbox",
                on: { click: _vm.handleAllSelect },
              }),
              _c("span", [_vm._v("全选")]),
              _c("span", { staticClass: "select-count" }, [
                _vm._v("已选择\n                    "),
                _c("span", { staticClass: "color-orange" }, [
                  _vm._v(_vm._s(_vm.selectCount)),
                ]),
                _vm._v("\n                    位候选人\n                "),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "work-table-btn work-table-btn-primary",
                attrs: { disabled: !_vm.canTag },
                on: { click: _vm.handleBatchTag },
              },
              [_vm._v("\n                打标签\n            ")]
            ),
            _c(
              "button",
              {
                staticClass: "work-table-btn work-table-btn-orange",
                attrs: { disabled: !_vm.canClaim },
                on: { click: _vm.handleBatchClaim },
              },
              [_vm._v("\n                认领\n            ")]
            ),
          ]),
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                "current-page": _vm.page.current + 1,
                "page-sizes": [20, 30, 50],
                "page-size": _vm.page.size,
                layout: "total, sizes, prev, pager, next, slot",
                total: _vm.page.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("前往"),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页"),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }