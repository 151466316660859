export const operationStatus = {
    '-8': '未筛选',
    '0': '未处理',
    '1': '已接受',
    '2': '已拒绝',
    '4': '面试',
    '5': '已安排',
    '6': '待评估',
    '7': '已反馈',
    '8': 'Offer',
    '9': '提交流水等材料',
    '10': '客户审批Offer',
    '11': '沟通Offer细节',
    '12': '等待接受Offer',
    '13': '双方确认Offer',
    '16': '已入职',
    '24': '发票开票中',
    '26': '发票已回款',
    '32': '已离职',
    '64': '面试待确认',
    '1024': '已淘汰',
};