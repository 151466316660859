var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "traceLogPopover",
      attrs: {
        placement: "bottom-start",
        width: "340",
        "popper-class": "operation-platform-popover",
        trigger: "hover",
        "visible-arrow": false,
        "open-delay": 700,
        offset: 500,
      },
      on: {
        show: function ($event) {
          return _vm.getInquiryLogs("show")
        },
      },
    },
    [
      _c(
        "span",
        { attrs: { slot: "reference" }, slot: "reference" },
        [
          _c("font-icon", {
            staticClass: "table-body-icon-big icon-inquiry",
            attrs: { href: "#icon-track-green" },
          }),
          _c("span", [_vm._v(" " + _vm._s(_vm.row.followRecordsCount))]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "table-popper-header inquiry-log-header" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.row.candidateName) +
                  " 的跟进记录\n            "
              ),
              _vm.row.receiverName &&
              _vm.row.receiverName == _vm.userInfo.realName
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-circle-plus-outline add-itap-icon",
                      },
                      on: { click: _vm.addFollowRecords },
                    },
                    [_vm._v("\n                添加\n            ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inquery-log-wrap" },
            [
              _vm._l(_vm.traceLogs, function (traceItem, index) {
                return _c(
                  "div",
                  { key: "tracelog_" + index, staticClass: "inquery-log-item" },
                  [
                    _c(
                      "div",
                      { staticClass: "record-hd" },
                      [
                        _c("avatar", {
                          attrs: {
                            enableCard: true,
                            enableLink: true,
                            src: traceItem.ownerAvatarUrl,
                            userId: traceItem.ownerId,
                          },
                        }),
                        _c("div", { staticClass: "record-title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "record-info",
                              attrs: {
                                title:
                                  traceItem.ownerRealName +
                                  "@" +
                                  traceItem.ownerNickName,
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "record-user",
                                domProps: {
                                  textContent: _vm._s(traceItem.ownerRealName),
                                },
                              }),
                              _c("span", { staticClass: "record-number" }, [
                                _vm._v(_vm._s("@" + traceItem.ownerNickName)),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "record-date" }, [
                            _vm._v(_vm._s(_vm._f("date")(traceItem.created))),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "record-hide-status",
                            class: traceItem.type === 0 ? "private" : "",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(traceItem.type === 0 ? "无效" : "有效") +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "inquiry-log-detail",
                        style: traceItem.audioRecordUrl
                          ? "margin-top: 5px;"
                          : "",
                      },
                      [
                        _c("div", { staticClass: "inquiry-log-detail-item" }, [
                          _c("i", [_vm._v("跟进内容：")]),
                          _c("span", [_vm._v(_vm._s(traceItem.content))]),
                        ]),
                        traceItem.jobName
                          ? _c(
                              "div",
                              { staticClass: "inquiry-log-detail-item" },
                              [
                                _c("i", [_vm._v("关联职位：")]),
                                _c("span", [_vm._v(_vm._s(traceItem.jobName))]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.userId === traceItem.ownerId,
                            expression: "userId === traceItem.ownerId",
                          },
                        ],
                        staticClass: "inquiry-log-footer",
                      },
                      [
                        traceItem.type === 1
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editInquiry(traceItem)
                                  },
                                },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-edit" },
                                }),
                                _c("span", { staticClass: "edit-text" }, [
                                  _vm._v("编辑"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteInquiry(traceItem)
                              },
                            },
                          },
                          [
                            _c("font-icon", {
                              attrs: { href: "#icon-ic_rubbish" },
                            }),
                            _c("span", { staticClass: "edit-text" }, [
                              _vm._v("删除"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm.traceLogs.length == 0
                ? _c("div", { staticClass: "empty-data" }, [
                    _c("span", { staticClass: "empty-img" }),
                    _c("p", { staticClass: "empty-text" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ])
                : _vm._e(),
              _vm.traceLogs.length < _vm.page.total
                ? _c(
                    "div",
                    {
                      staticClass: "inquiry-bottom",
                      on: { click: _vm.handleShowMore },
                    },
                    [
                      _c("span", { staticClass: "inquiry-show-more" }, [
                        _vm._v("加载更多"),
                        _c("i", { staticClass: "el-icon-d-arrow-right" }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }