var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "add-itap-dialog",
      class: {
        "single-add-itap": !_vm.isBatch,
        "is-guide": _vm.labelManagementBtnGuide,
      },
      attrs: {
        visible: _vm.isShow,
        "show-close": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
        close: _vm.hideDialog,
      },
    },
    [
      _vm.isBatch
        ? _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("\n        批量打标签\n        "),
            _c("div", { staticClass: "label-management-btn-guide" }, [
              _vm.isResume
                ? _c(
                    "span",
                    {
                      staticClass: "text-operate-btn blue label-management-btn",
                      class: { "is-btn-guide": _vm.labelManagementBtnGuide },
                      on: { click: _vm.showLabelManagement },
                    },
                    [_vm._v("\n                标签管理\n            ")]
                  )
                : _vm._e(),
              _vm.labelManagementBtnGuide
                ? _c("div", { staticClass: "step-guide" }, [
                    _c("span", { staticClass: "step-guide-box" }, [
                      _c("span", { staticClass: "guide-content" }, [
                        _vm._v(
                          "\n                        点击标签管理，即可对标签进行分类、编辑和删除。\n                    "
                        ),
                      ]),
                      _c("span", { staticClass: "guide-btn" }, [
                        _c(
                          "span",
                          {
                            staticClass: "text-operate-btn",
                            on: { click: _vm.hideLabelManagementGuide },
                          },
                          [_vm._v("不再提醒")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-operate-btn",
                            on: {
                              click: function ($event) {
                                _vm.labelManagementBtnGuide = false
                              },
                            },
                          },
                          [_vm._v("好的")]
                        ),
                      ]),
                    ]),
                    _c("span", { staticClass: "step-guide-arrows" }),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          !_vm.isBatch
            ? _c(
                "div",
                { staticClass: "table-popper-header" },
                [
                  _vm._v("\n            标签\n            "),
                  _vm.isResume
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            placement: "right-start",
                            effect: "light",
                            "popper-class": "itap-tooltip",
                            "visible-arrow": false,
                            offset: 20,
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n                    您了解“标签（Tag）”吗？\n                    "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                    标签是任由自己创造的，它是我们自己的一种分类方式。标签就是候选人的关键字、属性，帮助你快速分类查找候选人。比起传统的文件夹方式，标签管理的最大优点就是可以设定多个不同的标签第二行信息\n                "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "icon-question" },
                            [
                              _c("font-icon", {
                                staticClass:
                                  "talent-icon-middle question-hover",
                                attrs: { href: "#icon-talent_ql" },
                              }),
                              _c("font-icon", {
                                staticClass:
                                  "talent-icon-middle question-no-hover",
                                attrs: { href: "#icon-talent_qd" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "label-management-btn-guide" }, [
                    _vm.isResume
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "text-operate-btn blue label-management-btn",
                            class: {
                              "is-btn-guide": _vm.labelManagementBtnGuide,
                            },
                            on: { click: _vm.showLabelManagement },
                          },
                          [
                            _vm._v(
                              "\n                    标签管理\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.labelManagementBtnGuide
                      ? _c("div", { staticClass: "step-guide" }, [
                          _c("span", { staticClass: "step-guide-box" }, [
                            _c("span", { staticClass: "guide-content" }, [
                              _vm._v(
                                "\n                            点击标签管理，即可对标签进行分类、编辑和删除。\n                        "
                              ),
                            ]),
                            _c("span", { staticClass: "guide-btn" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "text-operate-btn",
                                  on: { click: _vm.hideLabelManagementGuide },
                                },
                                [_vm._v("不再提醒")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "text-operate-btn",
                                  on: {
                                    click: function ($event) {
                                      _vm.labelManagementBtnGuide = false
                                    },
                                  },
                                },
                                [_vm._v("好的")]
                              ),
                            ]),
                          ]),
                          _c("span", { staticClass: "step-guide-arrows" }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: { click: _vm.hideDialog },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("tag-add-area", {
            ref: "addItap",
            attrs: {
              isBatch: _vm.isBatch,
              isResume: _vm.isResume,
              tagData: _vm.list,
              tagDataIds: _vm.ids,
            },
          }),
        ],
        1
      ),
      _vm.isBatch
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.hideDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleBatchAddItap },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }