import { render, staticRenderFns } from "./operation-platform-table.vue?vue&type=template&id=61fbad83&"
import script from "./operation-platform-table.vue?vue&type=script&lang=js&"
export * from "./operation-platform-table.vue?vue&type=script&lang=js&"
import style0 from "./operation-platform-table.vue?vue&type=style&index=0&id=61fbad83&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61fbad83')) {
      api.createRecord('61fbad83', component.options)
    } else {
      api.reload('61fbad83', component.options)
    }
    module.hot.accept("./operation-platform-table.vue?vue&type=template&id=61fbad83&", function () {
      api.rerender('61fbad83', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/operation-platform/layout/operation-platform-table.vue"
export default component.exports