var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "inquiry-dialog",
      attrs: {
        visible: _vm.isShow,
        title: _vm.isEdit ? "编辑跟进记录" : "添加跟进记录",
        width: "610px",
        "show-close": false,
        "before-close": _vm.handleCancel,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "inquiry-item" }, [
        _c(
          "label",
          { class: _vm.validChecked ? "radio-item selected" : "radio-item" },
          [
            _c("span", { staticClass: "radio-wrap" }, [
              _c("input", {
                staticClass: "radio-input",
                attrs: { type: "radio", name: "remark" },
                on: {
                  click: function ($event) {
                    return _vm.handleValidSwitch(1)
                  },
                },
              }),
            ]),
            _c("span", [_vm._v("有效跟进")]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.validChecked,
                expression: "validChecked",
              },
            ],
            staticClass: "common-inquiry",
          },
          [
            _c("el-input", {
              staticClass: "remark-text",
              attrs: {
                type: "textarea",
                autosize: { minRows: 3, maxRows: 9 },
                placeholder: "添加一条新跟进记录",
                "show-word-limit": "",
                maxlength: "200",
              },
              model: {
                value: _vm.inquiryContent,
                callback: function ($$v) {
                  _vm.inquiryContent = $$v
                },
                expression: "inquiryContent",
              },
            }),
            _c("p", [_vm._v("绑定关联职位")]),
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  placeholder: "请选择",
                  "popper-class": "bind-job-options",
                  filterab: "",
                },
                model: {
                  value: _vm.bindJob,
                  callback: function ($$v) {
                    _vm.bindJob = $$v
                  },
                  expression: "bindJob",
                },
              },
              _vm._l(_vm.jobOptions, function (item, index) {
                return _c(
                  "el-option",
                  {
                    key: "job_" + index,
                    attrs: { label: item.name, value: item.id },
                  },
                  [
                    _c("div", { staticClass: "opt-item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "opt-job ellipsis",
                          domProps: { textContent: _vm._s(item.name) },
                        },
                        [_vm._v("产品经理")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "opt-company ellipsis",
                          domProps: { textContent: _vm._s(item.customerName) },
                        },
                        [_vm._v("腾讯")]
                      ),
                      _c("div", { staticClass: "opt-time" }, [
                        _vm._v(_vm._s(_vm._f("date")(item.created)) + " 发布"),
                      ]),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      !_vm.isEdit
        ? _c(
            "div",
            { staticClass: "inquiry-item" },
            [
              _c(
                "label",
                {
                  class: _vm.invalidChecked
                    ? "radio-item selected"
                    : "radio-item",
                },
                [
                  _c("span", { staticClass: "radio-wrap" }, [
                    _c("input", {
                      staticClass: "radio-input",
                      attrs: { type: "radio", name: "remark" },
                      on: {
                        click: function ($event) {
                          return _vm.handleValidSwitch(0)
                        },
                      },
                    }),
                  ]),
                  _c("span", [_vm._v("无效跟进")]),
                ]
              ),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invalidChecked,
                    expression: "invalidChecked",
                  },
                ],
                staticClass: "remark-text",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 3, maxRows: 9 },
                  placeholder: "添加一条新跟进记录",
                  "show-word-limit": "",
                  maxlength: "200",
                },
                model: {
                  value: _vm.invalidInquiryContent,
                  callback: function ($$v) {
                    _vm.invalidInquiryContent = $$v
                  },
                  expression: "invalidInquiryContent",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }