<template>
    <div class="candidate-operation">
        <el-dropdown
            placement="bottom-start"
            v-if="row.receiverName && row.receiverName == userInfo.realName"
            >
            <span class="el-dropdown-link name-cell" >
                <span class="operation-icon">
                    <font-icon class="table-body-icon operation-icon-default tip-margin" href="#icon-ic_act_grey"></font-icon>
                    <font-icon class="table-body-icon operation-icon-hover tip-margin" href="#icon-ic_act_green"></font-icon>
                </span>
            </span>
            <el-dropdown-menu class="shrink-dropdown-menu" slot="dropdown" >
                <template>
                    <el-dropdown-item 
                        @click.native="operation(0)"
                        >转移</el-dropdown-item>
                    <el-dropdown-item 
                        @click.native="operation(1)"
                        >打标签</el-dropdown-item>
                    <el-dropdown-item 
                        @click.native="operation(2)"
                        >填写跟进记录</el-dropdown-item>
                </template>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
export default {
    name: 'candidate-operation',
    props: {
        row: {
            type: Object
        },
    },
	components: {
	},
	data() {
		return {
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    created() {},
	mounted() {},
	methods: {
        operation(type) {
            this.$emit('operation-callback', type, this.row);
        },
    }
}
</script>
<style lang="scss" scope>
.candidate-operation{
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
    line-height: 19px;
}
</style>