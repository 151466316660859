<template>
    <el-popover
        ref="traceLogPopover"
        placement="bottom-start"
        width="340"
        popper-class="operation-platform-popover"
        trigger="hover"
        :visible-arrow="false"
        @show="getInquiryLogs('show')"
        :open-delay="700"
        :offset="500">
        <!-- @show="getInquiryLogs(resume.resumeListItem)" -->
        <span slot="reference">
            <font-icon class="table-body-icon-big icon-inquiry" href="#icon-track-green"></font-icon>
            <span>&nbsp;{{row.followRecordsCount}}</span>
        </span>

        <div v-loading="loading">
            <div class="table-popper-header inquiry-log-header">
                {{row.candidateName}} 的跟进记录
                <el-button
                    v-if="row.receiverName && row.receiverName == userInfo.realName"
                    icon="el-icon-circle-plus-outline add-itap-icon"
                    @click="addFollowRecords"
                    >
                    添加
                </el-button>
            </div>
            
            <div class="inquery-log-wrap">
                <div class="inquery-log-item" 
                    v-for="(traceItem, index) in traceLogs"
                    :key="'tracelog_' + index"
                    >
                    <!-- <div class="inquiry-log-userInfo">
                        <img :src="traceItem.creatorAvatar" alt="">
                        <span class="name">{{ traceItem.creatorRealName + '@' + traceItem.creatorNickName }}</span>
                        <span class="time">{{ traceItem.updated | date }}</span>
                    </div> -->
                    <div class="record-hd">
                        <avatar
                            :enableCard="true"
                            :enableLink="true"
                            :src="traceItem.ownerAvatarUrl"
                            :userId="traceItem.ownerId"
                        ></avatar>
                        <div class="record-title">
                            <div class="record-info" :title="traceItem.ownerRealName + '@' + traceItem.ownerNickName">
                                <span v-text="traceItem.ownerRealName" class="record-user"></span>
                                <span class="record-number">{{ '@' + traceItem.ownerNickName}}</span>
                            </div>
                            <div class="record-date">{{ traceItem.created | date }}</div>
                        </div>
                        <div
                            class="record-hide-status"
                            :class="traceItem.type === 0 ? 'private' : ''">
                            {{traceItem.type === 0 ? '无效' : '有效'}}
                        </div>
                    </div>
                    <!-- <div class="inquiry-log-content">{{ traceItem.content }}</div> -->

                    <!-- <pre 
                        class="inquiry-log-content" 
                        v-text="traceItem.content">
                    </pre> -->

                    <div
                        class="inquiry-log-detail"
                        :style="traceItem.audioRecordUrl ? 'margin-top: 5px;' : ''"
                        >
                        <div
                            class="inquiry-log-detail-item"
                            >
                            <i>跟进内容：</i>
                            <span>{{traceItem.content}}</span>
                        </div>
                        <div
                            v-if="traceItem.jobName"
                            class="inquiry-log-detail-item"
                            >
                            <i>关联职位：</i>
                            <span>{{traceItem.jobName}}</span>
                        </div>
                    </div>
                    <div class="inquiry-log-footer" v-show="userId === traceItem.ownerId">
                        <a href="javascript:;" 
                            @click="editInquiry(traceItem)" 
                            v-if="traceItem.type === 1">
                            <font-icon href="#icon-edit"></font-icon>
                            <span class="edit-text">编辑</span>
                        </a>
                        <a href="javascript:;" 
                            @click="deleteInquiry(traceItem)">
                            <font-icon href="#icon-ic_rubbish"></font-icon>
                            <span class="edit-text">删除</span>
                        </a>
                    </div>

                </div>

                <div class="empty-data" v-if="traceLogs.length == 0">
                    <span class="empty-img"></span>
                    <p class="empty-text">暂无数据</p>
                </div>

                <div class="inquiry-bottom"
                    v-if="traceLogs.length < page.total"
                    @click="handleShowMore">
                    <span class="inquiry-show-more">加载更多<i class="el-icon-d-arrow-right"></i></span>
                </div>
            </div>

        </div>
        
    </el-popover>
</template>

<script>
import moment from 'moment';
import operationPlatformService from '#/js/service/operationPlatformService.js';
import Avatar from '#/component/common/avatar.vue';
import emitter from '@src/js/mixins/emitter.js';

export default {
    name: 'trace-log',
	components: {
        Avatar,
	},
    mixins: [emitter],
    props: {
        resume: {
            type: Object,
            default: () => ({
                trackLogsCount: 3,
                realName: 'hahah'
            })
        },
        row: {
            type: Object,
            default: () => {},
        },
    },
	data() {
		return {
            loading: false,
            page: {
                start: 0,
                take: 3,
                total: 0,
            },
            traceLogs: [],
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        userId() {
            return this.userInfo.id;
        },
    },
    filters: {
        date(value) {
            return moment(value).format('YYYY-MM-DD H:mm');
        },
    },
    created() {

    },
	mounted() {
		
	},
	methods: {
        getInquiryLogs(type) {
            const params = {
                candidateId: this.row.candidateId,
                start: this.page.start * this.page.take,
                take: this.page.take,
            };
            if(type == 'show' && this.traceLogs.length > 0) return;

            this.loading = true;
            operationPlatformService.getFollowRecords(params)
            .then(res => {
                
                this.page.total = res.total;
                if(this.traceLogs.length == 0) {
                    this.traceLogs = res.list || [];
                } else {
                    this.traceLogs = [...this.traceLogs, ...res.list];
                }
            })
            .finally(() => {
                this.loading = false;
            });
        },
        addFollowRecords() {
            this.$emit('trace-opertaion-cb', this.row);
        },
        editInquiry(traceItem) {
            this.$emit('trace-opertaion-cb', this.row, traceItem);
        },
        deleteInquiry(traceItem) {

            this.$confirm(`删除后无法恢复，确定删除当前跟进记录？`, "提示", {
                showClose: false,
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
            .then(() => {

                operationPlatformService.deleteFollowRecord(traceItem.id)
                .then(res => {
                    shortTips(`跟进记录删除成功!`);
                    // 1.同步表的数据
                    this.dispatch('OperationPlatform', 
                        'handleOperationCb', 
                        'followRecordsCount', 
                        [this.row.id], 
                        this.row.followRecordsCount--);
                    this.coverRequest();
                })
                .finally(() => {
                    this.loading = false;
                });
            })
            .catch(() => {});
        },
        coverRequest() {
            this.page.start = 0;
            this.traceLogs.length = 0;
            this.getInquiryLogs();
        },
        handleShowMore() {
            this.page.start++;
            this.getInquiryLogs();
        },
    },
}
</script>

<style lang="scss" scoped>
.operation-platform-popover{
    margin-top: 5px;
    min-width: 50px;
    color: #666;
    padding: 20px;
}
.inquiry-log-header{
    margin-bottom: 13px !important;

    .el-button {
        float: right;
        min-width: 62px;
        height: 20px;
        margin: 0;
        padding: 0;
        line-height: 18px;
        background-color: #fff;
        border-radius: 4px;
        color: $primary;
        border-color: #36B495;
        &:hover {
            background-color: #36B495;
            color: #fff;

            .el-icon-circle-plus-outline.add-itap-icon{
                color: #fff;
            }
        }

        span{
            position: relative;
            top: -2px;
        }

        .el-icon-circle-plus-outline.add-itap-icon{
            line-height: 18px;
            color: $primary;
            font-size: 18px;
        }
    }
}
.inquery-log-wrap{
    max-height: 220px;
    overflow-y: auto;
    .inquery-log-item{
        height: auto;
        box-sizing: border-box;
        padding: 8px 0;
        border-bottom: 1px solid #ECECEC;
        .inquiry-log-userInfo{
            margin-bottom: 7px;
            img{
                display: inline-block;
                margin-right: 5px;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                border: 1px solid;
            }
            .name,.time{
                font-size: 12px;
            }

            .time{
                margin-left: 10px;
                color: #999;
            }
        }
        .inquiry-log-footer{
            height: auto;
            >a{
                color: #B9B9B9;
                font-size: 12px;
                margin-right: 20px;
                &:active,&:hover{
                    color: #38BC9D;
                }
                .edit-text{
                    margin-left: 9px;
                }
            }
        }
    }
    .inquiry-bottom{
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;
        text-align: center;
        line-height: 26px;
        cursor: pointer;
        .inquiry-show-more{
            color: #ccc;
            font-size: 12px;
        }
    }
}

// .inquiry-log-content {
//     margin-top: 10px;
//     max-height: 95px;
//     overflow-y: auto;
// }

.inquiry-log-detail {
    margin-top: 6px;
    &-item {
        line-height: 24px;
        font-size: 14px;
        color: #999;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        >i{
            display: inline-block;
            min-width: 70px;
            width: 70px;
        }
        >span{
            color: #666;
            flex-grow: 1;
        }
        // &::before {
        //     content: "·";
        //     font-size: 16px;
        //     font-weight: bold;
        //     line-height: 20px;
        //     margin-right: 7px;
        // }
    }
}
.empty-data {
    margin: 10px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-img {
        display: inline-block;
        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
        background-size: contain;
        width: 150px;
        height: 150px;
    }
    .empty-text {
        line-height: 20px;
        margin-bottom: 0px;
    }
}

.record-hd {
    display: flex;
    .record-title {
        max-width: 210px;
        margin-right: 10px;
        margin-left: 13px;
        color: #333;
        font-size: 14px;
        .record-date{
            color: #B9B9B9;
            font-size: 14px;
            margin-top: 3px;
        }
        .record-info{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .record-hide-status {
        margin-top: 2px;
        width: 64px;
        height: 32px;
        box-sizing: border-box;
        text-align: center;
        color: #38bc9d;
        font-size: 22px;
        border: 2px solid #38bc9d;
        border-radius: 4px;
        line-height: 28px;
        transform: scale(0.5);
        transform-origin: left top;
        &.private {
            border-color: #FA765D;
            color: #FA765D;
        }
    }
}
</style>